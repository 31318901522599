import React from "react";
import {Link} from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import "./index.scss";
import Press from "../../components/Press";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import {WorkerSlider} from "../../components/CustomSlider";
import GuardingMonitoring from "../../components/GuardingMonitoring";
import {Fade} from "../../components/Fade";

import Img1 from "../../assets/images/about/4-phase-01.gif";
import Img2 from "../../assets/images/about/4-phase-02.gif";
import Img3 from "../../assets/images/about/4-phase-03.gif";
import Img4 from "../../assets/images/about/4-phase-04.gif";
import {useDataContext} from "../../providers/DataProvider";
import HeaderBgImg from "../../assets/images/about/GettyImages-475144426-hardhat-1920.jpg";

const SafetyTechSmart = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="SafetyTechSmart_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>

        {
          pageData?.hero_section ?
              <GuardingMonitoring
                  title={pageData?.hero_section?.title ?? ""}
                  subTitle={pageData?.hero_section?.sub_title ?? ""}
                  desc={pageData?.hero_section?.desc ?? []}
                  type="Small"
              />
              :
              <GuardingMonitoring
                  title="MONITORING PRECISION HEALTH"
                  subTitle="IIOT / SAFETY TECH / SMART PPE"
                  desc={[
                    "The industrial revolution is entering its fourth wave – a world in which workers are increasingly valued, and technology is increasingly able to provide precision health monitoring. Developing the next generation of safety tech harnesses the sophistication of the Industrial Internet of Things (IIOT) and the personalization of Smart PPE to create a new world, in which workers are treated as highly-valued industrial athletes – with discrete physiological needs."
                  ]}
                  type="Small"
              />
        }

        <div className="Et_Pb_Section5">
          <Fade>
            <div className="Et_Pb_Container">
              {
                pageData?.desc_section?.length ?
                    pageData?.desc_section?.map((item, key) => {
                      return (
                          <div key={key} className={`Content_Txt ${key !== 0 ? 'Large' : ''}`}>{item ?? ''}</div>
                      )
                    })
                    :
                    <>
                      <div className="Content_Txt">With the turn of the 21<sup className="Order_Txt">st</sup> century, the digital world begins to fuse with the physical and biological worlds, transforming the industrial landscape. Machines, computers, and humans now work in a new, integrated manner to improve production and protect the worker. Smart PPE redefines the value of the worker from this point forward, enhancing each individual’s unique physiology while protecting the entire workforce from injury and illness.</div>
                      <div className="Content_Txt Large">It’s the age of the protected worker.</div>
                    </>
              }
            </div>
          </Fade>
        </div>

        <div className="Et_Pb_Section6">
          <Fade>
            {
              pageData?.phase_section ?
                  <div className="Et_Pb_Container">
                    <div className="Title_Txt">
                      <Link to={pageData?.phase_section?.link ?? ''}>
                        {pageData?.phase_section?.title ?? ''}
                      </Link>
                    </div>
                    <div className="Productivity_Grid4">
                      {
                        pageData?.phase_section_imgs?.map((item, key) => {
                          return (
                              <div key={key}>
                                <img src={item?.image} alt="alt img" title={item?.desc ?? ''} />
                                <div className={`Et_Pb_Txt Txt_Col${key + 1}`}>{item?.title ?? ''}</div>
                                {
                                  pageData?.phase_section?.content?.length > key && (
                                      <div className="Et_Pb_txt_Inner">
                                        <strong>{pageData?.phase_section?.content[key]?.name ?? ''}&nbsp;–&nbsp;</strong>
                                        {
                                          pageData?.phase_section?.content[key]?.desc?.map((subItem, index) => {
                                            if (index === 1) {
                                              return (
                                                  <a key={key + '_' + index} href={pageData?.phase_section?.content[key]?.link ?? ''}>{subItem ?? ''} &nbsp;</a>
                                              )
                                            } else {
                                              return (
                                                  <span key={key + '_' + index}>
                                                    {subItem ?? ''} &nbsp;
                                                  </span>
                                              )
                                            }
                                          })
                                        }
                                        <em>–&nbsp;{pageData?.phase_section?.content[key]?.relies ?? ''}</em>
                                      </div>
                                  )
                                }
                              </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  :
                  <div className="Et_Pb_Container">
                    <div className="Title_Txt">
                      THE &nbsp;
                      <Link to="https://ied.eu/project-updates/the-4-industrial-revolutions/">
                        FOUR PHASES OF INDUSTRIALIZATION
                      </Link>
                    </div>
                    <div className="Productivity_Grid4">
                      <div className="">
                        <img src={Img1} alt="alt img" />
                        <div className="Et_Pb_Txt Txt_Col1">1765</div>
                        <div className="Et_Pb_txt_Inner">
                          <strong>Mechanization</strong>
                          &nbsp; – the steam engine and railroads – &nbsp;
                          <em>relies on water</em>
                        </div>
                      </div>

                      <div className="">
                        <img src={Img2} alt="alt img" />
                        <div className="Et_Pb_Txt Txt_Col2">1870</div>
                        <div className="Et_Pb_txt_Inner">
                          <strong>Energy</strong>
                          &nbsp; – Electricity, gas, and oil – the automobile, airplane, telephone, and industrial steel – &nbsp;
                          <em>relies on mass production</em>
                        </div>
                      </div>

                      <div className="">
                        <img src={Img3} alt="alt img" />
                        <div className="Et_Pb_Txt Txt_Col3">1969</div>
                        <div className="Et_Pb_txt_Inner">
                          <strong>Automation</strong>
                          &nbsp; – Electronics, telecom, computers –   programmable logic controllers (PLCs) and robots – &nbsp;
                          <em>relies on robots</em>
                        </div>
                      </div>

                      <div className="">
                        <img src={Img4} alt="alt img" />
                        <div className="Et_Pb_Txt Txt_Col4">2000</div>
                        <div className="Et_Pb_txt_Inner">
                          <strong>Digital</strong>
                          &nbsp; – Fusion of <a href="https://www.brookings.edu/research/the-fourth-industrial-revolution-and-digitization-will-transform-africa-into-a-global-powerhouse/">digital, biological, and physical worlds</a> the internet, virtual reality, machine learning, artificial intelligence, cloud computing, the internet of things, biotechnology – &nbsp;
                          <em>relies on data</em>
                        </div>
                      </div>
                    </div>
                  </div>
            }
          </Fade>
        </div>

        {
          pageData?.help_section ?
              <FindOut
                  title={pageData?.help_section?.title ?? ''}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
              />
              :
              <FindOut
                  title='HOW WE ARE ADDRESSING COVID-19'
                  desc='The KENZEN solution helps detect invisible threats, such as COVID-19 and other infectious diseases.'
                  btnName='Find Out More'
                  link='/sales'
              />
        }

        <WorkerSlider
            container={pageData?.slider_section ?? ''}
        />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default SafetyTechSmart;
