import { useLocation } from "react-router-dom";
import { isValidPhoneNumber } from "libphonenumber-js";

import Icon1 from "../assets/images/soultion/KENZEN-icon-temp-300x300.gif";
import Icon2 from "../assets/images/soultion/KENZEN-icon-heart-300x300.gif";
import Icon3 from "../assets/images/soultion/KENZEN-icon-sweat-300x300.gif";
import Icon4 from "../assets/images/soultion/KENZEN-icon-activity-300x300.gif";

import BEST_ICON1 from "../assets/images/soultion/3-GOOD.gif";
import BEST_ICON2 from "../assets/images/soultion/3-BETTER.gif";
import BEST_ICON3 from "../assets/images/soultion/3-BEST-1.gif";

import HEALTH_MORNING_IMG1 from "../assets/images/soultion/Device-on-arm.png";
import HEALTH_MORNING_IMG2 from "../assets/images/soultion/4-together.jpg";
import HEALTH_MORNING_IMG3 from "../assets/images/soultion/live.jpg";
import HEALTH_MORNING_IMG4 from "../assets/images/soultion/Summary-Dashboard-Florida-Details-Public.png";

import LEADERSHIP_ICON1 from "../assets/images/about/digitalis-logo.jpg";
import LEADERSHIP_ICON2 from "../assets/images/about/royal-street-logo-1.jpg";
import LEADERSHIP_ICON3 from "../assets/images/about/WorkingCapitalLogo_WebsiteVersion.jpg";
import LEADERSHIP_ICON4 from "../assets/images/about/Gaingels-Logo.png";
import LEADERSHIP_ICON5 from "../assets/images/about/Sofia-logo.jpg";
import LEADERSHIP_ICON6 from "../assets/images/about/CL-Logo.jpg";
import LEADERSHIP_ICON7 from "../assets/images/about/WOMENS-CAP.gif";
import LEADERSHIP_ICON8 from "../assets/images/about/golden-high-res.jpg";
import LEADERSHIP_ICON9 from "../assets/images/about/SandHillAngelsLogo-Stacked.jpg";
import LEADERSHIP_ICON10 from "../assets/images/about/CCPLogo.jpg";
import LEADERSHIP_ICON11 from "../assets/images/about/examinetics-logo-JPG.jpg";
import LEADERSHIP_ICON12 from "../assets/images/about/KCRise-2.jpg";
import LEADERSHIP_ICON13 from "../assets/images/about/Manifold-Logo-Dark.jpg";
import LEADERSHIP_ICON14 from "../assets/images/about/SVP.jpg";

export const PAGINATION = 10;
export const CONTACT_US_PAGINATION = 6;
export const RECENT_PAGINATION = 5;

/**
 * Header
 * @type {({subMenus: [{name: string, link: string}, {name: string, link: string}, {name: string, link: string}, {name: string, link: string}], name: string, link: string}|{name: string, link: string}|{subMenus: [{name: string, link: string}, {name: string, link: string}, {name: string, link: string}, {name: string, link: string}], name: string, link: string}|{subMenus: ({name: string, link: string}|{name: string, link: string}|{name: string, link: string}|{name: string, link: string}|{name: string, link: string})[], name: string, link: string}|{subMenus: [], name: string, link: string})[]}
 */
export const HEADER_MENUS = [
  {
    name: "THE SOLUTION",
    link: "/end-to-end-health-and-safety-monitoring/",
    subMenus: [
      {
        name: "END-TO-END HEALTH & SAFETY MONITORING",
        link: "/end-to-end-health-and-safety-monitoring/",
      },
      { name: "BENEFITTING INDUSTRIES", link: "/benefitting-industries/" },
      {
        name: "SAFETY TECH IN DEVELOPMENT",
        link: "/safety-tech-in-development/",
      },
      {
        name: "SAFETY KEEPS EVERYONE WORKING",
        link: "/safety-keeps-everyone-working/",
      },
    ],
  },
  {
    name: "THE SCIENCE",
    link: "/superior-science/",
  },
  {
    name: "ABOUT US",
    link: "/leadership-team-board/",
    subMenus: [
      { name: "LEADERSHIP, TEAM AND BOARD", link: "/leadership-team-board/" },
      {
        name: "HOW WE ARE ADDRESSING COVID-19",
        link: "/how-we-are-addressing-covid-19/",
      },
      {
        name: "IIOT / SAFETY TECH / SMART PPE",
        link: "/iiot-safety-tech-smart-ppe/",
      },
      {
        name: "KENZEN SOLUTION PRIVACY POLICY",
        link: "/kenzen-solution-privacy-policy/",
      },
    ],
  },
  {
    name: "CONTACT US",
    link: "/sales/",
    subMenus: [
      { name: "SALES", link: "/sales/" },
      /*{name: 'PRESS INQUIRIES', link: '/press-inquiries/'},
      {name: 'R&D', link: '/rd/'},
      {name: 'JOBS', link: '/jobs/'},
      {name: 'INVESTOR INQUIRIES', link: '/investor-inquiries/'},*/
      { name: "SUPPORT", link: "/support/" },
    ],
  },
  {
    name: "NEWS / BLOG",
    link: "/news-blog/",
    subMenus: [],
  },
];

/**
 * Home
 * @type {({contents: string, title: string}|{contents: string, title: string}|{contents: string, title: string})[]}
 */
export const PROTECTION_TEXT = [
  {
    name: "workers",
    desc: "We keep workers safe, by continuously monitoring key physiological indicators, such as heart rate, over-exertion, and core body temperature.",
  },
  {
    name: "SAFETY MANAGERS",
    desc: "We keep managers aware, with real-time, de-identified data that predicts and prevents heat-related illness and injury.",
  },
  {
    name: "EHS & RISK DIRECTORS",
    desc: "We keep senior management informed, with broad risk management tools that aggregate, sort, and anonymize enterprise data.",
  },
];
export const SLIDER_TEXT = [
  {
    title: "Moving Workers Forward",
    sub_title: "THE KENZEN MISSION",
    desc: [
      "Critical frontline workers keep the economy going while protecting the most vulnerable among us. Industrial athletes at worksites and health care settings enter hazardous, high-risk environments every day – and it’s our mission to help keep them safe.",
    ],
  },
  {
    title: "OPTIMIZING WORKER HEALTH",
    sub_title: "THE KENZEN PROMISE",
    desc: [
      "The age of precision health monitoring is here, with Smart PPE that continuously monitors individual health markers end-to-end, against an established baseline. KENZEN solutions help managers optimize environmental and workforce conditions while giving individual workers power to monitor their own, unique physiological indicators.",
      "It’s the ultimate new tech, maximizing total worker health.",
    ],
  },
  {
    title: "Advocating for Worker Privacy",
    sub_title: "THE KENZEN COMMITMENT",
    desc: [
      "The twin pillars of KENZEN's commitment are safety and privacy. Safety monitoring is most effective when used by the entire workforce, and the best way to ensure adoption is to focus privacy concerns around the individual worker. Trust is the one thing money can’t buy – but with it, you can turn continuous monitoring into improved health for your workers and increased productivity overall.",
    ],
  },
];

export const TECH_DEVELOPMENT_DATA = [
  { image: Icon1, desc: "Core body temperature" },
  { image: Icon2, desc: "Heart rate with \n multi-LED PPG sensor" },
  { image: Icon3, desc: "Core body temperature and \n sweat rate" },
  { image: Icon4, desc: "Activity levels,\n with motion\n metrics" },
];

export const BEST_DATA = [
  {
    image: BEST_ICON1,
    title: "CONVENTIONAL",
    desc: "Many companies use spot temperature checking and incident report reviews, which compile data but don’t predict future outbreaks.",
  },
  {
    image: BEST_ICON2,
    title: "AVAILABLE",
    desc: "A better way is to continuously, and privately, monitor your entire workforce so illness and injury can be both predicted and prevented.",
  },
  {
    image: BEST_ICON3,
    title: "AVAILABLE NOW",
    desc: "Best-in-class innovation is underway, with the KENZEN machine + deep learning solution that learns as it monitors, enabling managers to adjust workers and environments, thus avoiding situations that cause illness and injury.",
  },
];

/**
 * Health Morning Data
 */
export const HEALTH_MORNING_DATA = [
  {
    image: HEALTH_MORNING_IMG1,
    title: "SMART PPE DEVICE",
    desc: "This compact, waterproof device records biometric data from a flat surface in a highly effective, unobtrusive way.",
  },
  {
    image: HEALTH_MORNING_IMG2,
    title: "MOBILE APP",
    desc: "This personalized application empowers the individual worker to self-monitor key physiological indicators, resulting in increased worker awareness and adoption.",
  },
  {
    image: HEALTH_MORNING_IMG3,
    title: "TEAM VIEW MOBILE DASHBOARD",
    desc: "This team-focused application alerts managers to deviations from baselines, allowing them to intervene, privately and quickly, with individual workers.",
  },
  {
    image: HEALTH_MORNING_IMG4,
    title: "CLOUD-BASED ENTERPRISE SOLUTION",
    desc: "This risk management and productivity tool advises EHS directors and risk managers about broad safety trends, giving them the power to aggregate, anonymize, and analyze enterprise data.",
  },
];

export const HEALTH_MORNING_WHO = [
  {
    name: "THE WORKER",
    desc: " Empowers real-time self-monitoring against a personal baseline of key physiological indicators",
  },
  {
    name: "THE SAFETY MANAGER",
    desc: "Enables prediction and prevention of injury and illness, in the moment, with de-identified team data",
  },
  {
    name: "EHS & RISK DIRECTORS",
    desc: "Envisions broad risk management tools that aggregate, sort, and anonymize enterprise data",
  },
];

export const HEALTH_MORNING_BENEFITS = [
  {
    title: "KENZEN FEATURES & BENEFITS",
    content: [
      "Individual worker biometric device and mobile application",
      "Small, waterproof device encourages worker self-monitoring of personal health stats",
      "Mobile app allows for worker self-detection of health alerts",
      "Personalized data is de-identified at management level",
      "Web-based team dashboard",
      "Safety intelligence risk management views that analyze and create safety reports",
      "Machine learning system",
      "Customizable integrations and Cloud APIs extract and expose data in usable client formats",
      "Actionable insights based on millions of real-time data points",
    ],
  },
  {
    title: "HOW KENZEN WORKS",
    content: [
      "Continuous safety monitoring is both real-time and highly accurate",
      "Detection is contextual to the worker’s physiology and their discrete environment",
      "Data is hyper-personalized, with individual baselines and de-identified data at the management level",
      "Millions of precise data points translate to actionable insights, in real-time, allowing managers to provide immediate support",
      "Machine learning predictive models deploy across workforce populations, giving managers tools to prevent future heat-related injury and illness",
    ],
  },
];

export const KENZEN_MORNITORS = [
  { image: Icon1, desc: "Core body temperature" },
  { image: Icon2, desc: "Heart rate,\n with multi-LED PPG sensor" },
  { image: Icon3, desc: "Core body temperature\n and sweat rate" },
  { image: Icon4, desc: "Activity levels,\n with motion metrics" },
];

/**
 * Science
 */
export const PREVIEWED_PAPER_DATA = [
  {
    icon_txt: "f",
    title: "Scientific Rigor",
    desc: "The KENZEN solution, based on non-invasive, gold standard-validated methods, reliably and accurately measures core temperature on a continuous basis. Workers’ physiological responses are precisely measured in real-time, leveraging sophisticated algorithms that create the ideal blend of protection and usability.",
  },
  {
    icon_txt: "",
    title: "Largest Data Set",
    desc: "The KENZEN data set, drawn from continuous temperature monitoring over 119 trials and 105,532 minutes of data, is both accurate and vast, covering a 24-hour range of inputs — from high exertion to normal daily activities. Our data was collected using gold standard methods, and we developed and validated our algorithms by comparing them to gold standard core body temperature collected in this dataset.",
  },
  {
    icon_txt: "",
    title: "University Affiliations",
    desc: "The KENZEN work, based on years of collaboration with four highly reputable universities worldwide, has resulted in the superior scientific crafting of our product. The highest level of scholarly peer review research was conducted to perfect our algorithms, design our data set, and produce the final KENZEN device.",
  },
  {
    icon_txt: "",
    title: "Expert Team",
    desc: "The KENZEN team, composed of top industry experts and heat scientists, exists to protect workers from heat injury. Our goal is to maximize our scientific rigor, data set, and academic collaborations to ensure the prevention of heat illness and injury to our workers worldwide.",
  },
];

export const ANALYZES_DATA = [
  { image: Icon1, desc: "Core body temperature" },
  { image: Icon2, desc: "Heart rate with\n multi-LED PPG sensor" },
  { image: Icon3, desc: "Sweat rate" },
  { image: Icon4, desc: "Activity levels,\n with motion\n metrics" },
];

/**
 * About
 */
export const LEADERSHIP_IMGS = [
  {
    img: LEADERSHIP_ICON1,
    title: "digitalis-logo",
    link: "https://digitalisventures.com/",
    maxWidth: 130,
  },
  {
    img: LEADERSHIP_ICON2,
    title: "royal-street-logo-1",
    link: "https://www.royalstreet.vc/",
    maxWidth: 150,
  },
  {
    img: LEADERSHIP_ICON3,
    title: "WorkingCapitalLogo_WebsiteVersion",
    link: "https://workingcapitalfund.com/",
    maxWidth: 130,
  },
  {
    img: LEADERSHIP_ICON4,
    title: "Gaingels-Logo",
    link: "https://gaingels.com/",
    maxWidth: 130,
  },
  {
    img: LEADERSHIP_ICON5,
    title: "Sofia-logo",
    link: "https://sofiafund.com/",
    maxWidth: 110,
  },
  {
    img: LEADERSHIP_ICON6,
    title: "CL-Logo",
    link: "https://citylight.vc/",
    maxWidth: 105,
  },
  {
    img: LEADERSHIP_ICON7,
    title: "WOMENS-CAP",
    link: "https://womenscapitalconnection.com/",
    maxWidth: 100,
  },
  {
    img: LEADERSHIP_ICON8,
    title: "golden-high-res",
    link: "https://goldenangelsinvestors.com/",
    maxWidth: 200,
  },
  {
    img: LEADERSHIP_ICON9,
    title: "SandHillAngelsLogo-Stacked",
    link: "https://www.sandhillangels.com/",
    maxWidth: 100,
  },
  {
    img: LEADERSHIP_ICON10,
    title: "CCPLogo",
    link: "https://www.connectivity.vc/",
    maxWidth: 83,
  },
  {
    img: LEADERSHIP_ICON11,
    title: "examinetics-logo-JPG",
    link: "https://www.examinetics.com/",
    maxWidth: 120,
  },
  {
    img: LEADERSHIP_ICON12,
    title: "KCRise-2",
    link: "https://www.kcrisefund.com/",
    maxWidth: 170,
  },
  {
    img: LEADERSHIP_ICON13,
    title: "Manifold-Logo-Dark",
    link: "https://www.manifold.group/",
    maxWidth: 120,
  },
  {
    img: LEADERSHIP_ICON14,
    title: "SVP",
    link: "https://www.spv.com/",
    maxWidth: 120,
  },
];

export const LEADERSHIP_SLIDER_TEXT = [
  {
    title: "GROWING THE VISION OF OUR FOUNDERS",
    desc: "When strong, committed people have a compelling vision for product innovation, it’s contagious.",
  },
  {
    title: "",
    desc: "Sonia Sousa held the vision of KENZEN far before the technology was conceived. With PhDs in neural networks and spectroscopy, and an MBA thrown in for good measure, Sonia became interested in the role of key physiological indicators when her brother was diagnosed with Stage 4 cancer – after exhibiting no symptoms of the disease. When Sonia herself became ill in early 2018, her vision had largely become real, with all of the levers in place to bring KENZEN solution to market. Named after the Japanese word for “of sound health,” KENZEN captures Sonia’s passion for monitoring physiological markers and preventing injury and illness, on a massive scale.",
  },
  {
    title: "",
    desc: "The magic of KENZEN – a biometric solution that predicts injury and illness before they happen – was Sonia’s dream, and her co-founder Steve Pecko was influential in bringing that dream to the present stage. With a highly-tuned passion for industrial product development and a rare skill for elegant product design, Steve’s eye for beauty brought KENZEN into being.",
  },
  {
    title: "",
    desc: "The current management team at KENZEN is committed to bringing the magic and sophistication of KENZEN  to life.",
  },
];

/**
 * Privacy Policy
 */
export const WEBSITE_PRIVACY_POLICY = [
  {
    title: "",
    content: [
      "Kenzen, Inc. (“<strong>Kenzen</strong>“) operates kenzenwear.com and may operate other websites. It is Kenzen’s policy to respect your privacy regarding any information we may collect while operating our websites.",
    ],
  },
  {
    title: "Website Visitors",
    content: [
      "Like most website operators, Kenzen collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Kenzen’s purpose in collecting non-personally identifying information is to better understand how Kenzen’s visitors use its website. From time to time, Kenzen may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.",
      "Kenzen also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on Kenzen.com blogs/sites. Kenzen only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below, except that commenter IP addresses and email addresses are visible and disclosed to the administrators of the blog/site where the comment was left.",
    ],
  },
  {
    title: "Gathering of Personally-Identifying Information",
    content: [
      "Certain visitors to Kenzen’s websites choose to interact with Kenzen in ways that require Kenzen to gather personally-identifying information. The amount and type of information that Kenzen gathers depends on the nature of the interaction. For example, we ask visitors who contact us on kenzenwear.com to provide a name and email address. Those who engage in transactions with Kenzen are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Kenzen collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with Kenzen. Kenzen does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.",
    ],
  },
  {
    title: "Aggregated Statistics",
    content: [
      "Kenzen may collect statistics about the behavior of visitors to its websites. Kenzen may display this information publicly or provide it to others. However, Kenzen does not disclose personally-identifying information other than as described below.",
    ],
  },
  {
    title: "Protection of Certain Personally-Identifying Information",
    content: [
      "Kenzen discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Kenzen’s behalf or to provide services available at Kenzen’s websites, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Kenzen’s websites, you consent to the transfer of such information to them. Kenzen will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Kenzen discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Kenzen believes in good faith that disclosure is reasonably necessary to protect the property or rights of Kenzen, third parties or the public at large. If you are a registered user of an Kenzen website and have supplied your email address, Kenzen may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with Kenzen and our products. If you send us a request (for example via email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Kenzen takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.",
    ],
  },
  {
    title: "Cookies",
    content: [
      "A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Kenzen uses cookies to help Kenzen identify and track visitors, their usage of Kenzen website, and their website access preferences. Kenzen visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Kenzen’s websites, with the drawback that certain features of Kenzen’s websites may not function properly without the aid of cookies.",
    ],
  },
  {
    title: "Business Transfers",
    content: [
      "If Kenzen, or substantially all of its assets, were acquired, or in the unlikely event that Kenzen goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Kenzen may continue to use your personal information as set forth in this policy.",
    ],
  },
  {
    title: "Ads",
    content: [
      "Ads appearing on any of our websites may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Kenzen and does not cover the use of cookies by any advertisers.",
    ],
  },
  {
    title: "Privacy Policy Changes",
    content: [
      "Although most changes are likely to be minor, Kenzen may change its Privacy Policy from time to time, and in Kenzen’s sole discretion. Kenzen encourages visitors to frequently check this page for any changes to its Privacy Policy. If you have a Kenzen.com account, you might also receive an alert informing you of these changes. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.",
    ],
  },
];

export const PREFERRED_COUNTRIES = [
  "us",
  "ca",
  "fr",
  "de",
  "jp",
  "cn",
  "au",
  "za",
  "in",
  "qa",
  "gb",
  "sa",
  "es",
];

/**
 * Submitting Format
 */
export const CONTACT_SALES_TODAY_INIT = {
  org: {
    val: "",
    type: "text",
    placeholder: "Company Name*",
    required: true,
  },
  contact_name: {
    val: "",
    type: "text",
    placeholder: "Contact Name*",
    required: true,
  },
  phone: {
    val: "",
    countryCode: "us",
    type: "tel",
    placeholder: "Phone Number*",
    required: true,
  },
  email: {
    val: "",
    type: "email",
    placeholder: "Email*",
    required: true,
  },
  intrinsic_explosive: {
    val: "",
    type: "select",
    placeholder: "Require Intrinsic Safety?*",
    required: true,
  },
  industry: {
    val: "",
    type: "select",
    placeholder: "Industry*",
    required: true,
  },
  why_contact: {
    val: "",
    type: "select",
    placeholder: "Why are you contacting Kenzen?*",
    required: true,
  },
  where_find: {
    val: "",
    type: "select",
    placeholder: "Where did you find us?*",
    required: true,
  },
  purchase_timeframe: {
    val: "",
    type: "select",
    placeholder: "Purchase Timeframe?*",
    required: true,
  },
  message: {
    val: "",
    type: "textarea",
    placeholder: "Tell us more",
    maxLength: 405,
    twoSpan: true,
  },
  recaptcha: {
    val: "",
    type: "captcha",
    required: true,
    twoSpan: true,
  },
};

export const PRESS_INQUIRIES_INIT = {
  firstname: {
    val: "",
    type: "text",
    placeholder: "First Name*",
    required: true,
  },
  lastname: {
    val: "",
    type: "text",
    placeholder: "First Name*",
    required: true,
  },
  title: {
    val: "",
    type: "text",
    placeholder: "Title",
  },
  org: {
    val: "",
    type: "text",
    placeholder: "Company / Organization*",
    required: true,
  },
  email: {
    val: "",
    type: "email",
    placeholder: "Email*",
    required: true,
  },
  phone: {
    val: "",
    countryCode: "us",
    type: "tel",
    placeholder: "Phone*",
    required: true,
  },
  message: {
    val: "",
    type: "textarea",
    placeholder: "Inquiry / Storyline",
    maxLength: 405,
    twoSpan: true,
  },
  recaptcha: {
    val: "",
    type: "captcha",
    required: true,
    twoSpan: true,
  },
};

export const CONTACT_RD_INIT = {
  firstname: {
    val: "",
    type: "text",
    placeholder: "First Name*",
    required: true,
  },
  lastname: {
    val: "",
    type: "text",
    placeholder: "First Name*",
    required: true,
  },
  title: {
    val: "",
    type: "text",
    placeholder: "Title",
  },
  org: {
    val: "",
    type: "text",
    placeholder: "Company, University, or Institute*",
    required: true,
  },
  email: {
    val: "",
    type: "email",
    placeholder: "Email*",
    required: true,
  },
  phone: {
    val: "",
    countryCode: "us",
    type: "tel",
    placeholder: "Phone (xxx) xxx-xxxx *",
    required: true,
  },
  isFund: {
    val: "",
    type: "radio",
    placeholder:
      "Do you require funding for your project or is it fully-funded?",
  },
  message: {
    val: "",
    type: "textarea",
    placeholder: "Inquiry or Brief Proposal",
    maxLength: 405,
    twoSpan: true,
  },
  recaptcha: {
    val: "",
    type: "captcha",
    required: true,
    twoSpan: true,
  },
};

export const JOBS_INIT = {
  firstname: {
    val: "",
    type: "text",
    placeholder: "First Name*",
    required: true,
  },
  lastname: {
    val: "",
    type: "text",
    placeholder: "Last Name*",
    required: true,
  },
  email: {
    val: "",
    type: "email",
    placeholder: "Email*",
    required: true,
  },
  phone: {
    val: "",
    countryCode: "us",
    type: "tel",
    placeholder: "Phone*",
    required: true,
  },
  cv: {
    val: "",
    type: "upload",
    placeholder: "Please upload your CV as a PDF",
    required: true,
    twoSpan: true,
  },
  recaptcha: {
    val: "",
    type: "captcha",
    required: true,
    twoSpan: true,
  },
};

export const INVESTOR_RELATIONS_INIT = {
  firstname: {
    val: "",
    type: "text",
    placeholder: "First Name*",
    required: true,
  },
  lastname: {
    val: "",
    type: "text",
    placeholder: "First Name*",
    required: true,
  },
  title: {
    val: "",
    type: "text",
    placeholder: "Title",
  },
  org: {
    val: "",
    type: "text",
    placeholder: "Company, University, or Institute*",
    required: true,
  },
  email: {
    val: "",
    type: "email",
    placeholder: "Email*",
    required: true,
  },
  phone: {
    val: "",
    countryCode: "us",
    type: "tel",
    placeholder: "Phone (xxx) xxx-xxxx *",
    required: true,
  },
  message: {
    val: "",
    type: "textarea",
    placeholder: "Questions",
    maxLength: 405,
    twoSpan: true,
  },
  recaptcha: {
    val: "",
    type: "captcha",
    required: true,
    twoSpan: true,
  },
};

export const SUPPORT_TEAM_INIT = {
  org: {
    val: "",
    type: "text",
    placeholder: "Company*",
    required: true,
  },
  contact_name: {
    val: "",
    type: "text",
    placeholder: "Contact Name*",
    required: true,
  },
  title: {
    val: "",
    type: "text",
    placeholder: "Title",
    required: true,
  },
  email: {
    val: "",
    type: "email",
    placeholder: "Email*",
    required: true,
  },
  phone: {
    val: "",
    countryCode: "us",
    type: "tel",
    placeholder: "Phone Number*",
    required: true,
  },
  location: {
    val: "",
    type: "text",
    placeholder: "Worksite location*",
    required: true,
  },
  concern: {
    val: "",
    type: "select",
    placeholder: "What is your area of concern?*",
    required: true,
    twoSpan: true,
  },
  message: {
    val: "",
    type: "textarea",
    placeholder: "Please describe the issue you are experiencing.",
    maxLength: 200,
    twoSpan: true,
  },
  recaptcha: {
    val: "",
    type: "captcha",
    required: true,
    twoSpan: true,
  },
};

export const POSSIBLE_TYPES = [
  "contact_sales_today",
  "press_inquiries",
  "contact_rd",
  "jobs",
  "contact_investor_relations",
  "contact_the_kenzen_support_team",
];

export const SUCCESS_CONFIRM_MSG = {
  contact_sales_today:
    "Thank you for contacting the KENZEN sales team. Someone will be in touch, soon.",
  press_inquiries:
    "Thank you for your press inquiry. Someone will be in touch, soon.",
  contact_rd:
    "Thank you for contacting the KENZEN R&D team. Someone will be in touch, soon.",
  jobs: "Thank you for contacting KENZEN Human Resources. Someone will be in touch, soon.",
  contact_investor_relations:
    "Thank you for contacting the KENZEN investor relations team. Someone will be in touch, soon.",
  contact_the_kenzen_support_team:
    "Thank you for contacting the KENZEN support team. Someone will be in touch, soon.",
};

export const LOCATIONS_ARR = [
  { title: "CORPORATE", name: "TIMES SQUARE, NY, NY" },
  { title: "SCIENCE", name: "SILICON VALLEY, CA" },
  { title: "OPERATIONS", name: "KANSAS CITY, MO" },
];

export const SUBMIT_FORM_ORDER = [
  "org",
  "contact_name",
  "title",
  "email",
  "phone",
  "industry",
  "team_size",
  "health_budget",
  "intrinsic_explosive",
  "referral",
  "why_contact",
  "where_find",
  "purchase_timeframe",
  "cv",
  "location",
  "concern",
  "isFund",
  "message",
  "recaptcha",
];
/**
 * Getting value from params
 * @param param
 * @returns {any}
 * @constructor
 */
export const GetCurrentParam = (param) => {
  const location = useLocation();
  let urlParams = new URLSearchParams(location?.search) ?? {};
  return param ? Number(urlParams?.get(param) ?? 0) : "";
};

export const JoinStringFromArr = (arr) => {
  let list = [];
  arr.forEach((it) => {
    list.push(it?.attributes?.name);
  });

  return list.join(", ");
};

/**
 * Check if string contains only spaces
 */
export const checkIfSpacesOnly = (str) => {
  return !str?.replace(/\s/g, "").length;
};

export const checkPhoneNumberValidation = (value, country) => {
  if (!value) {
    return false;
  }
  return isValidPhoneNumber(value, country?.toUpperCase() ?? "US");
};

export const checkEmailValidation = (email) => {
  return email && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);
  // let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
  // return email.match(validRegex);
};

/**
 * Checking if value is valid
 * str = {[key]: {val: '', placeholder: '', type: 'text', required: true, countryCode: 'us', error: '',}}
 */
export const onCheckErrorMsg = (str, key) => {
  let error = "";
  if (str?.required) {
    if (
      ([
        "title",
        "contact_name",
        "org",
        "email",
        "phone",
        "industry",
        "intrinsic_explosive",
        "why_contact",
        "where_find",
        "purchase_timeframe",
        "location",
        "concern",
      ]?.includes(key) &&
        checkIfSpacesOnly(str.val)) ||
      (key === "cv" && !str.val)
    ) {
      error = "This field is required.";
    }

    if (
      process.env.REACT_APP_ENV === "production" &&
      process.env.REACT_APP_RECAPTCHA_SITE_KEY &&
      key === "recaptcha" &&
      checkIfSpacesOnly(str.val)
    ) {
      error = "The reCAPTCHA was invalid. Go back and try it again.";
    }

    if (!error) {
      if (key === "email" && !checkEmailValidation(str?.val)) {
        error =
          "The email address entered is invalid, please check the formatting (e.g. email@domain.com).";
      } else if (
        key === "phone" &&
        !checkPhoneNumberValidation(str?.val, str?.countryCode)
      ) {
        error = "Please enter a valid phone number.";
      }
    }
  }

  return error;
};

export const resetJsonFormat = (data) => {
  return data.map((it) => ({
    img: it?.attributes?.image?.data?.attributes?.url ?? "",
    maxWidth: it?.attributes?.max_width,
    pWidth: it?.attributes?.p_width,
    title: it?.attributes?.title ?? "",
    link: it?.attributes?.link ?? "",
  }));
};
