import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./index.scss";
import {Fade} from "../Fade";
import {useSubmitContext} from "../../providers/SubmitProvider";
import {
  queryPostContact,
  queryPostUploadFile,
} from "../../http";
import CustomPhoneInput from "../PhoneInput";
import {
  onCheckErrorMsg,
  POSSIBLE_TYPES,
  SUCCESS_CONFIRM_MSG,
} from "../../constants";
import ConfirmModal from "../ConfirmModal";

const ContactSales = ({
  type,
  title,
  description,
  initValues,
}) => {
  const {setIsLoader, enumerations} = useSubmitContext();
  const recaptchaRef = React.createRef();
  const [values, setValues] = React.useState(initValues ?? {});
  const [error, setError] = React.useState('');
  const [isClickSubmit, setIsClickSubmit] = React.useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = React.useState(false);

  const valueKeys = React.useMemo(() => {
    if (!initValues) return [];
    setValues(initValues);
    return Object.keys(initValues);
  }, [initValues]);

  const onCheckValues = (data) => {
    let isValidation = true;
    let _keys = Object.keys(data);

    _keys.forEach(it => {
      let error = onCheckErrorMsg(data[[it]], it);
      if (error) {
        isValidation = false;
      }
      data = {
        ...data,
        [it]: { ...data[[it]], error: error, }
      }
    });
    setValues(data);
    setError(!isValidation ? 'There was a problem with your submission. Please review the fields below.' : '');

    return isValidation;
  };

  const onChangeValues = (key, val, countryCode) => {
    let data = {
      ...values,
      [key]: { ...values[[key]], val: key === 'email' ? val?.toLowerCase() : val, countryCode: countryCode }
    };
    if (isClickSubmit) {
      onCheckValues(data);
    } else {
      setValues(data);
    }
  };

  const onChangeRecaptcha = () => {
    const recaptchaToken = recaptchaRef?.current?.getValue();
    onChangeValues('recaptcha', recaptchaToken);
  };

  const onSubmit = async () => {
    setIsClickSubmit(true);

    if (type) {
      let isValidation = onCheckValues(JSON.parse(JSON.stringify(values)));

      if (isValidation) {
        let submitValues = {type: type};
        let keys = Object.keys(values);
        let uploadFile;
        keys?.forEach(it => {
          if (values[[it]]?.val || ['firstname', 'lastname', 'email', 'phone', 'recaptcha'].includes(it)) {
            if (values[[it]]?.type === 'upload' && values[[it]]?.val) {
              uploadFile = values[[it]].val;
            } else {
              submitValues = Object.assign({}, submitValues, { [it]: values[[it]].val.trim() ?? '' })
            }
          }
        });
        setIsLoader(true);

        const formData = new FormData();
        if (uploadFile) {
          formData.append('files', uploadFile);
          let resUpload =
              await queryPostUploadFile(formData)
                  .catch(e => {
                    setError(e?.message ?? '');
                    setIsLoader(false);
                  });

          if (resUpload?.data?.length && resUpload?.data[0]?.id) {
            submitValues = Object.assign({}, submitValues, {
              'cv': resUpload?.data[0]?.id,
            })
          } else {
            return null;
          }
        }

        await queryPostContact(submitValues)
            .then(() => {
              setValues(initValues);
              setError('');
              setIsShowSuccessModal(true);
              setIsClickSubmit(false);
            })
            .catch(e => {
              setError(e?.response?.data?.error?.message ?? '');
            })
            .finally(() => {
              setIsLoader(false);
            });
      }
    }
  };

  React.useEffect(() => {
    if (isShowSuccessModal) {
      recaptchaRef?.current?.reset();
    }
  }, [isShowSuccessModal, recaptchaRef]);

  if (!POSSIBLE_TYPES?.includes(type)) return null;

  return (
      <div className="ContactSales_Body">
        <Fade>
          <div className="Contents">
            <div className="Grid2">
              <div className="Et_pb_text_6">
                <div className="Et_Title">{title ?? ''}</div>
                <div className="Et_Desc">{description ?? ''}</div>
              </div>

              <div style={{width: '100%'}}>
                {
                  error && <div className="ErrorBody">{error}</div>
                }
                <div className="Form_Grid2">
                  {
                    valueKeys?.map((item, key) => {
                      let inputType = initValues[[item]];
                      let options = enumerations[[item]] ?? [];

                      return (
                          <div
                              key={key}
                              className={initValues[[item]]?.twoSpan ? "SpanTwo" : ""}
                          >
                            {
                              ['text', 'email'].includes(inputType?.type) ?
                                  <input
                                      type={inputType?.type}
                                      placeholder={inputType?.placeholder ?? ''}
                                      value={values[[item]]?.val}
                                      onChange={e => onChangeValues(item,e.target.value || '')}
                                      required={values[[item]]?.required ?? false}
                                  />
                                  :
                                  inputType?.type === 'tel' ?
                                      <CustomPhoneInput
                                          containerClass="PhoneNumberContainer"
                                          inputClass="PhoneNumberInput"
                                          dropdownClass="PhoneNumberDropdown"
                                          value={values[[item]]?.val}
                                          onChange={(value, countryCode) => onChangeValues(item, value, countryCode)}
                                      />
                                      :
                                      inputType?.type === 'select' ?
                                          <select
                                              value={values[[item]]?.val}
                                              required={values[[item]]?.required ?? false}
                                              onChange={e => onChangeValues(item,e.target.value || '')}
                                          >
                                            <option className="TxtDisabled" value="">{inputType?.placeholder ?? ''}</option>
                                            {
                                              options?.map((selItem, selKey) => {
                                                return (
                                                    <option key={key + "_" + selKey} value={selItem}>{selItem}</option>
                                                )
                                              })
                                            }
                                          </select>
                                          :
                                          inputType?.type === 'textarea' ?
                                              <>
                                                <textarea
                                                    placeholder={inputType?.placeholder ?? ''}
                                                    maxLength={inputType?.maxLength ?? 200}
                                                    value={values[[item]]?.val}
                                                    onChange={e => onChangeValues(item,e.target.value || '')}
                                                />
                                                <div className="Message_Max">
                                                  {values[[item]]?.val?.length || 0} of {inputType?.maxLength ?? 200} max characters
                                                </div>
                                              </>
                                              :
                                              inputType?.type === 'radio' ?
                                                  <div className="RadioBody">
                                                    <div className="RadioTxt">{values[[item]]?.placeholder ?? ''}</div>
                                                    <div className="RadioFlex">
                                                      <div className="Base_Justify_Left">
                                                        <input
                                                            type='radio'
                                                            id="funding"
                                                            name="fund"
                                                            checked={values[[item]]?.val === 'require_funding'}
                                                            onChange={e => e.target.checked ? onChangeValues(item, 'require_funding') : null}
                                                        />
                                                        <label
                                                            htmlFor="funding"
                                                            className={values[[item]]?.val === 'require_funding' ? "CheckedRadio" : ""}
                                                        >
                                                          Require funding
                                                        </label>
                                                      </div>

                                                      <div className="Base_Justify_Left">
                                                        <input
                                                            type='radio'
                                                            id="funded"
                                                            name="fund"
                                                            checked={values[[item]]?.val === 'fully_funded'}
                                                            onChange={e => e.target.checked ? onChangeValues(item, 'fully_funded') : null}
                                                        />
                                                        <label
                                                            htmlFor="funded"
                                                            className={values[[item]]?.val === 'fully_funded' ? "CheckedRadio" : ""}
                                                        >
                                                          Fully funded
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  :
                                                  inputType?.type === 'upload' ?
                                                      <div className="FileUploadBody">
                                                        <div className="TitleTxt">
                                                          {values[[item]]?.placeholder ?? ''}
                                                          <span className="AsteriskTxt">*</span>
                                                        </div>

                                                        <div className="UploadContents">
                                                          <input
                                                              type='file'
                                                              multiple={false}
                                                              accept="application/pdf"
                                                              onChange={e => onChangeValues(item, e.target.files?.length ? e.target.files[0] : null)}
                                                          />
                                                          <div className="FileMaxSize">Max. file size: 50MB.</div>
                                                        </div>
                                                      </div>
                                                      :
                                                      (process.env.REACT_APP_ENV === 'production' && inputType?.type === 'captcha' && process.env.REACT_APP_RECAPTCHA_SITE_KEY) ?
                                                          <>
                                                            <div className={`Captcha_Txt ${values[[item]]?.error ? 'ErrorCaptcha' : ''}`}>CAPTCHA</div>
                                                            <ReCAPTCHA
                                                                ref={recaptchaRef}
                                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                                onChange={onChangeRecaptcha}
                                                            />
                                                          </>
                                                          :
                                                          null
                            }

                            {
                              values[[item]]?.error && (
                                  <div className="ErrorTxt">
                                    {values[[item]].error}
                                  </div>
                              )
                            }
                          </div>
                      );
                    })
                  }
                </div>

                <div className="Btn_Submit" onClick={() => onSubmit()}>Submit</div>
              </div>
            </div>
          </div>
        </Fade>

        <ConfirmModal
            show={isShowSuccessModal}
            onOk={() => setIsShowSuccessModal(false)}
            okText='Ok'
            header='THANK YOU'
            subHeader={SUCCESS_CONFIRM_MSG[[type]]}
            subheader='Thank you for contacting the KENZEN support team. Someone will be in touch, soon.'
        />
      </div>
  )
};
export default ContactSales;
