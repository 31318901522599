import React from "react";
import CheckCircle from "../../assets/images/commons/check-circle.svg";
import style from "./confirmModal.module.scss";
import clsx from "clsx";

const Children = () => (
    <div className="Base_Align_C">
      <img src={CheckCircle} alt="flower" width={105} height={105}/>
    </div>
);

const ConfirmModal = (
{
  show,
  onOk,
  header,
  subHeader,
  okText,
  content = <Children/>,
}) => {

  return (
      <div className={clsx(style.Overlay, show ? style.Show : style.None)}>
        <div className={clsx(style.Modal, show ? style.Show : style.None)}>
          <div className={clsx(style.CloseIcon)} onClick={onOk} />
          <div className={clsx(style.ModalHeader)}>
            <span>
              {header}
            </span>
          </div>
          <div className={clsx(style.ModalSubHeader)}>
            <span>
              {subHeader}
            </span>
          </div>
          <div className={clsx(style.ModalBody)}>
            {content}
          </div>

          <div className={clsx(style.ModalFooter)}>
            <div className="text-center">
              <button
                  className={clsx(style.ButtonActive)}
                  onClick={onOk}
              >
                {okText ?? ''}
              </button>
            </div>
          </div>
        </div>
      </div>
  )
};

export default ConfirmModal;