import React from "react";
import {Link} from "react-router-dom";
import "./index.scss";

import {useDataContext} from "../../providers/DataProvider";
import {GetCurrentParam} from "../../constants";

const Sidebar = () => {
  const {
    data,
    keyword,
    setKeyword,
    categoryId,
    setCategoryId,
  } = useDataContext();

  const [keywordTmp, setKeywordTmp] = React.useState(keyword ?? '');
  const id = GetCurrentParam('id');

  const onSelectCategory = (val) => {
    setCategoryId(val);
  };

  return (
      <div className="Sidebar_Body">
        <div className="Search_Body Base_Justify_Left">
          <input
              className="Search_Input"
              value={keywordTmp ?? ''}
              onChange={e => setKeywordTmp(e.target.value)}
              onKeyUp={e => e.keyCode === 13 ? setKeyword(keywordTmp ?? '') : null}
          />
          <div className="Search_Btn" onClick={() => setKeyword(keywordTmp ?? '')}>Search</div>
        </div>

        <div className="Recent_Txt">Recent</div>
        <div className="Recent_Link_Body">
          {
            data?.posts?.map((item, key) => {
              if (key > 4) return null;

              return (
                  <div key={key} className="Blog_Link">
                    <Link to={`/news-blog/detail?id=${item?.id}`} className={id === item?.id ? 'Active' : ''}>
                      {item?.title ?? ''}
                    </Link>
                  </div>
              )
            })
          }
        </div>

        <div className="Recent_Txt Subject_Txt">Subjects</div>

        <select value={categoryId} onChange={e => onSelectCategory(e.target.value || '')}>
          <option value="" disabled>Select Category</option>
          {
            data?.categories?.map((item, key) => {
              return (
                  <option key={key} value={item?.id}>{item?.name ?? ''}</option>
              )
            })
          }
        </select>
      </div>
  )
};
export default Sidebar;