import React, {useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./index.scss";
import Sidebar from "../../components/Sidebar";
import {useDataContext} from "../../providers/DataProvider";
import {GetCurrentParam} from "../../constants";

const AuthorDetail = () => {
  const {
    authorNews,
  } = useDataContext();

  const refScroll = useRef(null);
  const navigate = useNavigate();
  let id = GetCurrentParam('id');

  const onTopScroll = () => {
    refScroll.current.scrollIntoView({behavior: "smooth"});
  };


  const onPageLink = (page) => {
    if (page === 1) {
      navigate(`/news-blog/author?id=${id}`);
    } else {
      navigate(`/news-blog/author?id=${id}&page=${page}`);
    }

    onTopScroll();
  };

  return (
      <div className="AuthorDetail_Body" ref={refScroll}>
        <div className="Et_Pb_Container">
          <div className="Grid2">
            <div className="Contents">
              {
                authorNews?.posts?.map((item, key) => {
                  return (
                      <div key={key} style={{marginBottom: 80}}>
                        <div className="Title_Txt">
                          {item?.title ?? ''}
                        </div>
                        <div className="Author_Txt">
                          {
                            item?.authorName && (
                                <Link to={'/news-blog/author?id=' + (id ?? '')}>by {item?.authorName ?? ''}</Link>
                            )
                          }

                          {
                            item?.date && (
                                <>
                                  &nbsp; | &nbsp; {new Date(item?.date).toLocaleString("en-US", { year: "numeric", month: "short", day: "2-digit"})}
                                </>
                            )
                          }

                          {
                            item?.categoryNames && (
                                <Link to={'/news-blog/author?id=' + (id ?? '')}> &nbsp; | &nbsp;{item?.categoryNames ?? ''}</Link>
                            )
                          }
                        </div>
                        {
                          item?.content && (
                              <div className="NewsContainer" dangerouslySetInnerHTML={{__html: item?.content}} />
                          )
                        }
                      </div>
                  )
                })
              }

              <div className="Base_Flex_Space Pagination_Btn_Body">
                {
                  authorNews?.pagination?.page !== authorNews?.pagination?.pageCount ?
                      <div onClick={() => onPageLink(authorNews?.pagination?.page + 1)}>« Older Entries</div>
                      :
                      <div />
                }

                {
                  authorNews?.pagination?.page !== 1 && (
                      <div onClick={() => onPageLink(authorNews?.pagination?.page - 1)}>Next Entries »</div>
                  )
                }
              </div>
            </div>

            <Sidebar />
          </div>
        </div>
      </div>
  )
};
export default AuthorDetail;