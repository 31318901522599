import React from "react";
import {Link} from "react-router-dom";
import "./index.scss";
import {Fade} from "../../components/Fade";
import {useDataContext} from "../../providers/DataProvider";

const SolutionPrivacyPolicy = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="SolutionPrivacyPolicy_Body">
        <div className="Et_Pb_Section1">
          <Fade>
            <div className="Et_Pb_Container">
              <div className="Title_Txt">KENZEN SOLUTION PRIVACY POLICY</div>
            </div>
          </Fade>
        </div>

        <div className="Et_Pb_Section2">
          {
            pageData?.solution ?
                <div className="Et_Pb_Container" dangerouslySetInnerHTML={{__html: pageData?.solution ?? ''}} />
                :
                <div className="Et_Pb_Container" >
                  <Fade>
                    <strong>The Kenzen Solution Privacy Policy</strong>
                    <div className="Content_Txt Pt2">
                      The Kenzen Services (defined below) are designed to keep workers safe on the job and was designed with worker privacy in mind.  Please read this Privacy Policy carefully to learn how we collect, use, share, and otherwise process information relating to individuals (“Personal Data”), and to learn about your rights and choices regarding your Personal Data.
                    </div>
                    <div className="Content_Txt">
                      At Kenzen, Inc., (“Kenzen,” “we,” “our,” or “us”) our products and services are used to predict and prevent potentially avoidable health conditions for industrial workers and other individuals that are at risk of injury including but not limited to fatigue and heat exhaustion (the “Services”). Our Services collect information from you when you wear the Kenzen device and download the Kenzen Mobile App. To view our corporate privacy notice, which governs our information practices when we collect information from visitors to our corporate website, please visit &nbsp;
                      <Link to="/website-privacy-policy/">our website privacy policy.</Link>
                    </div>
                    <div className="Content_Txt">
                      We provide this Privacy Policy so that all users of our Services understand how we collect, use, and share the information collected through our Services and what information we share with other third-parties and partners with whom we conduct business (“Partners”).  Kenzen provides the Services on behalf of itself and usually at the request of your employer. You should consult any privacy notices or guidelines provided by your employer to understand how your employer uses and discloses information collected through Kenzen’s Services. Kenzen is not responsible for how your employer collects and processes Personal Data.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>What information do we collect?</strong>
                    <div className="Content_Txt Pt2">
                      We collect information you provide to us when you use our Services or interact with us in any other manner. This information may include your name, address, email address, phone number, date of birth, and any other information you choose to provide.  We collect this information when you contact us, submit an inquiry, or interact with us through any other method in conjunction with your use of the Services.
                    </div>
                    <div className="Content_Txt">
                      To provide the Services, Kenzen products are equipped with sensors that collect health information including without limitation heart rate, activity and motion, core body temperature and skin temperature, sweat loss, and perspiration rate.  Kenzen products also collect geolocation information to detect local temperature and humidity.
                    </div>
                    <div className="Content_Txt">
                      We collect technical information when you or our Partners interact with us with our Services, including device type, browser type, device identifier, IP address, pages visited and other activities when using the Services, and time and date of visit, and other information we collect through the use of cookies and similar technology.
                    </div>
                    <div className="Content_Txt">
                      Our Services will advise you, and seek your consent, for information collection, usage, and disclosure where consent is required by law.  In other cases, your provision of information is sometimes required by law and at other times is a result of a contractual requirement.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>How do we use the information that we collect?</strong>
                    <div className="Content_Txt Pt2">
                      We process your information for a variety of purposes—most importantly, to provide Services to you and your employer.  For example, you will receive alerts based on personal real-time and historical physiological information, daily worksite temperature and humidity information, and your own work/rest schedule.  Your employer will receive alerts, summary reports of actions taken by workers and symptoms experienced by workers, daily worksite temperature and humidity information, and general work/rest schedules through use of a centralized dashboard.  We may also anonymize and aggregate usage data collected through your use of the Services and share such de-identified, anonymous, and/or aggregated data with our Partners, including but not limited to your employer, for our general business purposes, for example publicly disclosing information regarding trends in the general use of our Services. Such anonymized, aggregated data is the sole property of Kenzen and may be used without restriction.
                    </div>
                    <div className="Content_Txt">
                      Additionally, we process your information for other purposes, including the following:
                    </div>
                    <ul>
                      <li>To respond to your inquiries or contact you as you request;</li>
                      <li>To operate and improve the Service and our business, including for analytics purposes;</li>
                      <li>To comply with the law; and/or</li>
                      <li>As otherwise disclosed at the time personal information was collected.</li>
                    </ul>
                    <div className="Content_Txt">
                      We use automatic decision-making, including profiling, to help employers identify and reduce the occurrence of heat stress, fatigue, and over exertion.
                    </div>
                    <div className="Content_Txt">
                      Where we intend to process your personal information for a purpose other than the purpose for which it was collected or as expressly stated in this Privacy Policy, we will provide you with information regarding the purpose for the processing, as well as other relevant information, prior to processing your personal information for the new purpose.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>How do we share the information that we collect?</strong>
                    <div className="Content_Txt Pt2">
                      We may share the information we collect with certain parties, including the following:
                    </div>
                    <ul>
                      <li>Our Partners, which include employers and other customers that use our Services</li>
                      <li>Affiliated companies;</li>
                      <li>Third parties that perform services on behalf of Kenzen;</li>
                      <li>As part of a corporate sale, merger, or acquisition, or other transfer of all or part of our assets, including but not limited to as part of a bankruptcy proceeding;</li>
                      <li>Pursuant to a subpoena, court order, governmental inquiry, or other legal process or as otherwise required by law, or to protect our rights or the rights of third parties; or</li>
                      <li>With your consent or as otherwise disclosed at the time of data collection or sharing.</li>
                    </ul>
                    <div className="Content_Txt">
                      Through our dashboards, our Partners can access user information to help them make decisions that will reduce risks related to heat stress, fatigue, and over exertion.  Where possible, employers rely on deidentified or anonymized information to make these decisions.  Employers can also access information about individual users to facilitate real-time interventions.  At any time, individuals using our Services can opt-out of the Services by deleting or discontinuing use of the Services, including discontinuing use of the sensors.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Third Party Links and Tools</strong>
                    <div className="Content_Txt Pt2">
                      The Services may provide links to websites or applications maintained by third parties, including social media pages. We do not control the privacy practice of those websites or applications, and they are not covered by this privacy notice.  You should review the privacy notices of other websites or applications that you use to learn about their data practices.
                    </div>
                    <div className="Content_Txt">
                      The Services may also include integrated social media tools or “plug-ins,” such as social networking tools offered by third parties.  If you use these tools to share personal information or you otherwise interact with these features on the Services, those companies may collect information about you and may use and share such information in accordance with your account settings, including by sharing such information with the general public.  Your interactions with these third parties and the use of their features are governed by their privacy notices.  We encourage you to carefully read the privacy notices of any accounts you create and use.
                    </div>
                  </Fade>

                  <Fade>
                    <div className="Content_Txt Pt3">
                      Privacy Rights Under Specific Laws
                    </div>
                    <strong>California Users</strong>
                    <div className="Content_Txt Pt2">
                      When we provide Services to our customers, we are considered a “service provider” under the California Consumer Privacy Act (“CCPA”).  In that case, the Partner’s privacy notice governs the use of “personal information” that is subject to the CCPA. For example, when we process personal information from our Services on behalf of your Employer, it is the Employer’s privacy notice that controls.  Kenzen will retain, use, and disclose personal information in accordance with our contract with your employer and this Privacy Policy.
                    </div>
                    <div className="Content_Txt">
                      California residents have certain rights with respect to the personal information collected by Partners.  If you are a California resident, you may exercise the following rights regarding your personal information, subject to certain exceptions and limitations:
                    </div>
                    <ul>
                      <li>The right to know the categories and specific pieces of personal information Partners collect, use, disclose, and sell about you, the categories of sources from which we collected your personal information, our purposes for collecting or selling your personal information, the categories of your personal information that we have either sold or disclosed for a business purpose, and the categories of third parties with which we have shared personal information;</li>
                      <li>The right to request that we and your employer delete the personal information collected from you.</li>
                      <li>The right to opt out of sale(s) of your personal information.</li>
                      <li>The right not to receive discriminatory treatment for the exercise of the privacy rights conferred by the CCPA.</li>
                    </ul>
                    <div className="Content_Txt">
                      To exercise any of the above rights, please reach out to your employer, as applicable. Kenzen will work with the Partner, at its direction, to facilitate your rights request.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>European Users</strong>
                    <div className="Content_Txt Pt2">
                      This section applies to individuals that use the Services from the European Economic Area, United Kingdom, and Switzerland (collectively, “EU Users”). As in the United States, we process personal information on behalf of your employer, and it is their privacy notices that generally control.  Nevertheless, we want to provide you with some information about how Kenzen processes personal information from European users on behalf of our Partners.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Rights Available to EU Users</strong>
                    <div className="Content_Txt Pt2">
                      If you are an EU User, you may have certain rights under local privacy and data protection laws, including without limitation the General Data Protection Regulation (“GDPR”).  Please contact the employer that has provided you with access to the Service to exercise those rights. Kenzen will, where able, facilitate responses to your requests at the direction of the employer.
                    </div>
                    <div className="Content_Txt">
                      You may have some or all of the following rights:
                    </div>
                    <ul>
                      <li>To request access to your personal information;</li>
                      <li>To request that employer rectify or erase personal information;</li>
                      <li>To request that employer restricts or blocks the processing of personal information;</li>
                      <li>Under certain circumstances, to receive personal information about you that employer stores and transmits, including requesting that personal information is provided directly to another entity, e. a right to data portability; and</li>
                      <li>To withdraw consent to processing personal information.</li>
                    </ul>
                    <div className="Content_Txt">
                      Additionally, you have the right to lodge a complaint against the employer.  To do so, contact the supervisory authority in your country of residence.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Legal Basis for Processing</strong>
                    <div className="Content_Txt Pt2">
                      Kenzen processes personal information with consent, as indicated by your acceptance of the end user license agreement related to use of the Mobile App, and your acceptance of this Privacy Policy.  For example, when Kenzen processes personal information to provide services you request, such as to provide personal information to third parties at your direction.
                    </div>
                    <div className="Content_Txt">
                      On certain occasions, we process your personal information when it is necessary for the performance of a contract to which you are a party, such as to provide services to you that you have requested.  We may also process your personal information to respond to your inquiries concerning our products and services.
                    </div>
                    <div className="Content_Txt">
                      On other occasions, we process your personal information where required by law.  We may also process your personal information if necessary to protect your interests or the interests of a third party.
                    </div>
                    <div className="Content_Txt">
                      Additionally, we process your personal information when necessary to do so in our or our legitimate interest, for example to provide the Service to our customers, including through third parties, to facilitate the services provided to our customers by our partners, keep records of customer activity and information, to administer a customer’s accounts, including payment, to respond to a customer’s service or support requests, for marketing, promotional and sales purposes, for data security purposes, for statistical, analytical machine learning and product/service enhancement purposes, and this interest is not overridden by your data protection rights.
                    </div>
                    <div className="Content_Txt">
                      If the processing of personal information is necessary and there is no statutory basis for such processing, we will ask for your consent to process your personal information.  You have the right to withdraw your consent to processing of personal information at any time.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Transfers of Personal Information</strong>
                    <div className="Content_Txt Pt2">
                      Please be aware that the personal information we collect may be transferred to and maintained on servers or databases located outside your state, province, country, or other jurisdiction, where the privacy laws may not be as protective as those in your location.  If you are located outside of the United States, please be advised that we process and store personal information in the United States.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Retention</strong>
                    <div className="Content_Txt Pt2">
                      We will process and store your information in accordance with this Privacy Policy, or as permitted by law.  After expiration of that period, the corresponding information is routinely deleted, unless its retention is required by law.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Security Measures</strong>
                    <div className="Content_Txt Pt2">
                      Kenzen has established reasonable precautions to protect personal information from loss, misuse, unauthorized access, disclosure, alteration or destruction, which include contractual, administrative, physical, organizational and technical safeguards designed to comply with applicable laws.  Technical measures include use of firewall protection and encryption technology where appropriate. Nevertheless, no method of transmission over the Internet, or method of electronic storage, is one hundred percent (100%) secure, however. Therefore, we cannot guarantee absolute security. If you have any questions about security on our Service, please contact us at &nbsp;
                      <Link to="mailto:privacy@kenzen.com">privacy@kenzen.com</Link>.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Your Choices</strong>
                    <div className="Content_Txt Pt2">
                      To opt-out of the Services, please terminate your account by using your account settings. To opt out of emails from Kenzen, you can use the link provided at the bottom of each marketing message.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Updating Your Information</strong>
                    <div className="Content_Txt Pt2">
                      You may request to review, change, or update your contact information by emailing us at <Link to="mailto:admin@kenzen.com">admin@kenzen.com</Link> You may also update your contact information by accessing your account settings.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Changes to Our Privacy Policy</strong>
                    <div className="Content_Txt Pt2">
                      Kenzen will from time-to-time review and revise these practices.  We will post any changes to the privacy policy here.  Please refer back to this privacy policy on a regular basis.
                    </div>
                  </Fade>

                  <Fade>
                    <strong>Contact</strong>
                    <div className="Content_Txt Pt2">
                      If you have questions, comments, or concerns about this privacy notice, please contact us at: <Link to="mailto:privacy@kenzen.com">privacy@kenzen.com</Link>
                    </div>
                    <div className="Content_Txt">
                      For users located in the European Economic Area, the United Kingdom, or Switzerland, our Data Protection Officer may be contacted at: <Link to="mailto:privacy@kenzen.com">privacy@kenzen.com</Link>
                    </div>
                  </Fade>
                </div>
          }
        </div>
      </div>
  )
};
export default SolutionPrivacyPolicy;