import React from "react";
import "./index.scss";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import ContactSales from "../../components/ContactSales";
import News from "../../components/News";
import Locations from "../../components/Locations";
import Press from "../../components/Press";
import {useDataContext} from "../../providers/DataProvider";

import {
  SUPPORT_TEAM_INIT,
} from "../../constants";
import HeaderBgImg from "../../assets/images/contactUs/hands-short.jpg";

const Support = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="Support_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>
        <div className="Get_Started_Content Base_Fade_Animation">
          <div className="Today_Txt Base_Justify_Left">
            {pageData?.hero_section?.title ?? 'SUPPORT'}
          </div>
        </div>

        <ContactSales
            type="contact_the_kenzen_support_team"
            title={pageData?.submit_section?.title ?? "CONTACT THE KENZEN SUPPORT TEAM"}
            description={pageData?.submit_section?.desc ?? "Please fill out this support form and someone will get in touch with you as soon as possible."}
            initValues={pageData?.submit_section?.init_forms ?? SUPPORT_TEAM_INIT}
        />

        {
          pageData?.help_section ?
              <FindOut
                  title={<span style={{fontSize: 30}}>{pageData?.help_section?.title ?? ''}</span>}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
                  cssCls='Get_Alerts_Body'
              />
              :
              <FindOut
                  title={<span style={{fontSize: 30}}>GET ALERTS</span>}
                  desc='Stay updated on KENZEN news, launches, and more.'
                  btnName='JOIN OUR LIST'
                  link='/sales'
                  cssCls='Get_Alerts_Body'
              />
        }

        <Locations/>

        <News />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default Support;