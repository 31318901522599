import React, {useRef} from "react";

import "./index.scss";
import FindOut from "../../components/FindOut";
import Affiliations from "../../components/Affiliations";
import News from "../../components/News";
import Press from "../../components/Press";

import {useDataContext} from "../../providers/DataProvider";

const NewsBlog = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  const {setKeyword, setCategoryId} = useDataContext();
  const refScroll = useRef(null);

  React.useEffect(() => {
    setCategoryId('');
    setKeyword('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTopScroll = () => {
    refScroll.current.scrollIntoView({behavior: "smooth"});
  };

  return (
      <div className="NewBlog_Body">
        <div id="bodyId" ref={refScroll} />

        <News onTopScroll={onTopScroll} />

        {
          pageData?.help_section ?
              <FindOut
                  title={<span style={{fontSize: 30}}>{pageData?.help_section?.title ?? ''}</span>}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
                  cssCls='Get_Alerts_Body'
              />
              :
              <FindOut
                  title={<span style={{fontSize: 30}}>GET ALERTS</span>}
                  desc='Stay updated on KENZEN news, launches, and more.'
                  btnName='JOIN OUR LIST'
                  link='/sales'
                  cssCls='Get_Alerts_Body'
              />
        }

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default NewsBlog;