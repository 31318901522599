import React from "react";
import "./index.scss";
import {Fade} from "../../components/Fade";
import {WEBSITE_PRIVACY_POLICY} from "../../constants";
import {useDataContext} from "../../providers/DataProvider";

const WebsitePrivacyPolicy = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="WebsitePrivacyPolicy_Body">
        <div className="Et_Pb_Section1">
          <Fade>
            <div className="Et_Pb_Container">
              <div className="Title_Txt">WEBSITE PRIVACY POLICY</div>
            </div>
          </Fade>
        </div>

        <div className="Et_Pb_Section2">
          {
            pageData?.website ?
                <div className="Et_Pb_Container" dangerouslySetInnerHTML={{__html: pageData?.website ?? ''}} />
                :
                <div className="Et_Pb_Container">
                  {
                    WEBSITE_PRIVACY_POLICY?.map((item, key) => {
                      return (
                          <Fade key={key}>
                            {
                              item?.title && (
                                  <strong>{item?.title}</strong>
                              )
                            }

                            {
                              item?.content?.map((subItem, index) => {
                                return (
                                    <div key={key + '_' + index} className={`Content_Txt ${index === 0 ? 'Pt2' : ''}`} dangerouslySetInnerHTML={{__html: subItem ?? ''}} />
                                )
                              })
                            }
                          </Fade>
                      )
                    })
                  }
                </div>
          }
        </div>
      </div>
  )
};
export default WebsitePrivacyPolicy;