import React from "react";
import "./index.scss";
import {Fade} from "../../components/Fade";
import {useDataContext} from "../../providers/DataProvider";

const TermsOfUse = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="TermsOfUse_Body">
        <div className="Et_Pb_Section1">
          <Fade>
            <div className="Et_Pb_Container">
              <div className="Title_Txt">TERMS OF USE</div>
            </div>
          </Fade>
        </div>

        <div className="Et_Pb_Section2">
          {
            pageData?.content ?
                <div className="Et_Pb_Container" dangerouslySetInnerHTML={{__html: pageData?.content ?? ''}} />
                :
                <div className="Et_Pb_Container">
                  Please view our <a target="_blank" rel="noreferrer" href="https://kenzendevfoo.blob.core.windows.net/strapi-uploads/assets/KENZEN_TERMS_CONDITIONS_92fb333a85?updated_at=2023-05-31T08:09:25.804Z">Terms and Conditions</a>
                </div>
          }
        </div>
      </div>
  )
};
export default TermsOfUse;