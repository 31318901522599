import React from "react";
import "./index.scss";

import AuthorSectionBg from "../../assets/images/soultion/christopher-burns-368617-unsplash-ready-2.jpg";

const Industries = ({
  bgImage,
  title,
  authorDate,
}) => {
  return (
      <div className="Industry_Body" style={{backgroundImage: 'url(' + (bgImage ?? AuthorSectionBg) + ')'}}>
        <div className="Et_Pb_Title_Container Base_Fade_Animation">
          <div className="Title_Txt Base">{title ?? ''}</div>
          <div className="SubTiTle_Txt">{authorDate ?? ''}</div>
        </div>
      </div>
  )
};
export default Industries;