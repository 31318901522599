import {useEffect, useState} from "react";

export const useWindowScroll = () => {
  const [scrollY, setScrollY] = useState({
    scrollY: undefined,
    height: undefined,
    bottomPosition: false,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleWindow() {
      const wrappedElement = document.getElementById('bodyId');
      setScrollY({
        scrollY: window.scrollY,
        height: window.innerHeight,
        bottomPosition: wrappedElement.getBoundingClientRect().bottom <= window.innerHeight,
      })
    }

    window.addEventListener("scroll", handleWindow);
    handleWindow();
    return () => window.removeEventListener("scroll", handleWindow);
  }, []);
  return scrollY;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};