import React from "react";
import "./index.scss";
import ScheduleToday from "../../components/ScheduleToday";
import Press from "../../components/Press";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import {WorkerSlider} from "../../components/CustomSlider";
import {
  BEST_DATA,
} from "../../constants";
import GuardingMonitoring from "../../components/GuardingMonitoring";
import {Fade} from "../../components/Fade";
import {useDataContext} from "../../providers/DataProvider";
import HeaderBgImg from "../../assets/images/soultion/welder.jpg";

const KeepsEveryOneWorking = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="KeepsEveryOneWorking_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>

        <ScheduleToday/>

        {
          pageData?.hero_section ?
              <GuardingMonitoring
                  title={pageData?.hero_section?.title ?? ""}
                  subTitle={pageData?.hero_section?.sub_title ?? ""}
                  desc={pageData?.hero_section?.desc ?? []}
              />
              :
              <GuardingMonitoring
                  title="SAFETY KEEPS EVERYONE WORKING"
                  subTitle=""
                  desc={[
                    "Health conditions can take a workforce down, sometimes in just a few days. When injury and illness strike a labor force, it’s only a matter of time before everyone is off the job. A focus on individual illness detection before it happens can make all the difference.",
                    "There’s a better way."
                  ]}
              />
        }

        <div className="Et_Pb_Section_5">
          <Fade>
            <div className="Et_Content_5">
              {
                pageData?.desc_section?.length ?
                    pageData?.desc_section?.map((item, key) => {
                      return (
                          <div key={key} className="Et_Pb_Text_Inner">{item ?? ''}</div>
                      )
                    })
                    :
                    <>
                      <div className="Et_Pb_Text_Inner">While good illness detection includes techniques such as spot temperature monitoring and rear-view mirror incident review, better methods implement monitoring that looks forward and prevents the spread of illness.</div>
                      <div className="Et_Pb_Text_Inner">KENZEN is developing best-in-class, intuitive solution which learns as it monitors, revolutionizing safety monitoring and enabling managers to predict and prevent heat illness before it happens. </div>
                    </>
              }
            </div>
          </Fade>
        </div>

        <div className="Et_Pb_Section_6">
          <div className="Et_Pb_Row_3">
            <div className="Et_Pb_Text_Inner Base_Fade_Animation">
              THE KENZEN END-GAME
            </div>
          </div>

          <Fade>
            <div className="Et_Pb_Row_4 Base_Fade_Animation">
              {
                (pageData?.end_game_image_text ?? BEST_DATA)?.map((item, key) => {
                  return (
                      <div key={key}>
                        <img src={item?.image} className="Bg_Img" alt="best img" />
                        <div className="Title_Txt">{item?.title ?? ''}</div>
                        <div className="Desc_Txt">{item?.desc ?? ''}</div>
                      </div>
                  )
                })
              }
            </div>
          </Fade>
        </div>

        {
          pageData?.help_section ?
              <FindOut
                  title={pageData?.help_section?.title ?? ''}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
              />
              :
              <FindOut
                  title='HOW WE ARE ADDRESSING COVID-19'
                  desc='The KENZEN solution helps detect invisible threats, such as COVID-19 and other infectious diseases.'
                  btnName='Find Out More'
                  link='/sales'
              />
        }

        <WorkerSlider
            container={pageData?.slider_section ?? ''}
        />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default KeepsEveryOneWorking;
