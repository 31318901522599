import React from "react";
import "./index.scss";

const FindOut = ({
  title,
  desc,
  btnName,
  link,
  cssCls,
}) => {

  return (
      <div className={`FindOut_Body ${cssCls ?? ''}`}>
        <div className="Contents Base_Fade_Animation">
          <div className="How_We_Txt">{title ?? ''}</div>
          <div className="Diseases_Txt">
            {desc ?? ''}
          </div>
          {/* onClick={() => navigate(link ?? '')}*/}
          <div className="Btn_Find_Out Base_Mouse_Cursor">
            <a href={link ?? '#'} target="_blank" rel="noreferrer">{btnName ?? ''}</a>
          </div>
        </div>
      </div>
  )
};
export default FindOut;