import React from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import "./index.scss";
import {useDataContext} from "../../providers/DataProvider";
import {useWindowSize} from "../../utils/hooks";

const News = ({
  onTopScroll,
}) => {
  const {data} = useDataContext();
  const navigate = useNavigate();
  const location = useLocation();
  const getSize = useWindowSize();

  const list = React.useMemo(() => {
    if (!getSize?.width || !data?.posts?.length) return [];

    let _list = [...data?.posts];

    if (getSize?.width >= 992) {
      let splitLen = Math.floor(_list?.length / 3);
      let firstIndex = splitLen + (_list?.length / 3 === splitLen ? 0 : 1);
      let secondIndex = _list?.length - splitLen;

      return [
        _list.slice(0, firstIndex),
        _list.slice(firstIndex, secondIndex),
        _list.slice(secondIndex, )
      ];
    } else if (getSize?.width >= 600) {
      let splitLen = Math.floor(_list?.length / 2);

      return [
        _list.slice(0, _list?.length - splitLen),
        _list.slice(_list?.length - splitLen,)
      ];
    } else {
      return [_list];
    }
  }, [getSize?.width, data?.posts]);

  const onPageLink = (page) => {
    if (page === 1) {
      navigate(location?.pathname);
    } else {
      navigate(`${location?.pathname}?page=${page}`);
    }

    if (location?.pathname?.includes('news-blog') && onTopScroll) {
      onTopScroll();
    }
  };

  return (
      <div className="News_Body">
        <div className="Contents">
          <div className="Grid3">
            {
              list?.map((item, key) => {
                return (
                    <div key={key}>
                      {
                        item?.map((subItem, index) => {
                          return (
                              <div key={key + '_' + index} className="Item_Contents">
                                {
                                  subItem?.thumbnail && (
                                      <Link to={'/news-blog/detail?id=' + subItem?.id}>
                                        <img src={subItem?.thumbnail} className="Img_W" alt="news img" />
                                      </Link>
                                  )
                                }
                                <div className="Txt_Container">
                                  <Link to={'/news-blog/detail?id=' + subItem?.id}>
                                    <div className="Title_Txt">
                                      {subItem?.title ?? ''}
                                    </div>
                                  </Link>

                                  <div className="Meta_Txt">
                                    by
                                    <Link to={'/news-blog/author?id=' + subItem?.authorId}>
                                      {subItem?.authorName ?? ''}
                                    </Link>
                                    | {subItem?.date ? (new Date(subItem?.date)).toLocaleString("en-US", { year: "numeric", month: "short", day: "2-digit"}) : ''}
                                  </div>

                                  <Link to={'/news-blog/detail?id=' + subItem?.id}>
                                    <div className="Read_More">
                                      READ MORE
                                    </div>
                                  </Link>
                                </div>
                              </div>
                          )
                        })
                      }
                    </div>
                )
              })
            }
          </div>

          {
            data?.posts?.length ?
                <div className="Base_Flex_Space Pagination_Btn_Body">
                  {
                    data?.pagination?.page !== data?.pagination?.pageCount ?
                        <div onClick={() => onPageLink(data?.pagination?.page + 1)}>« Older Entries</div>
                        :
                        <div />
                  }

                  {
                    data?.pagination?.page !== 1 && (
                        <div onClick={() => onPageLink(data?.pagination?.page - 1)}>Next Entries »</div>
                    )
                  }
                </div>
                :
                <></>
          }
        </div>
      </div>
  )
};
export default News;
