import React from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./index.scss";
import ScheduleToday from "../../components/ScheduleToday";
import Press from "../../components/Press";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import {WorkerSlider} from "../../components/CustomSlider";
import GuardingMonitoring from "../../components/GuardingMonitoring";
import {Fade} from "../../components/Fade";
import {useDataContext} from "../../providers/DataProvider";
import HeaderBgImg from "../../assets/images/about/POC-man-examine-1920x1080-2.jpg";

const value1 = 2;
const value2 = 0.96;
const value3 = 1;

const AddressingCovid = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="AddressingCovid_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>

        <ScheduleToday/>

        {
          pageData?.hero_section ?
              <GuardingMonitoring
                  title={pageData?.hero_section?.title ?? ""}
                  subTitle={pageData?.hero_section?.sub_title ?? ""}
                  desc={pageData?.hero_section?.desc ?? []}
                  type="Small"
              />
              :
              <GuardingMonitoring
                  title="FIGHTING INVISIBLE THREATS"
                  subTitle="ADDRESSING COVID-19"
                  desc={[
                    "There have always been invisible, infectious threats to workers – seasonal flu, tuberculosis, and now COVID-19. With continuous end-to-end monitoring of individual physiological indicators, managers can stop contagious illness from spreading, sooner."
                  ]}
                  type="Small"
              />
        }

        <div className="Et_Pb_Section5">
          <Fade>
            <div className="Et_Pb_Container">
              {
                pageData?.desc_section?.length ?
                    pageData?.desc_section?.map((item, key) => {
                      return (
                          <div key={key} className="Content_Txt">{item ?? ''}</div>
                      )
                    })
                    :
                    <>
                      <div className="Content_Txt">Because fever is a leading indicator of serious illness, including COVID-19, it can be a key marker for contagious disease. Pandemics are spread by close contact and can spread like fire through your workforce, necessitating an effective means of prediction and prevention.</div>
                      <div className="Content_Txt">Continuous monitoring is far more private and efficient than spot-checking worker temps, allowing managers to intervene throughout the work day. With pandemics becoming more common in the modern age, it’s critical to protect workers from the spread of infectious diseases.</div>
                    </>
              }
            </div>
          </Fade>
        </div>

        <div className="Et_Pb_Section6">
          <Fade>
            <div className="Et_Pb_Container">
              <div className="Productivity_Grid3">
                {
                  pageData?.statistic_section?.length ?
                      pageData?.statistic_section?.map((item, key) => {
                        return (
                            <div key={key} className="Base_Align_C Base_Fade_Animation">
                              <CircularProgressbar className="progress-bar-animated" value={(item?.percentage ?? 0) / 100} maxValue={1} text={`${item?.percentage ?? 0}%`} />
                              <div className="Productivity_Title_Txt">{item?.title ?? ''}</div>
                              <div className="Desc_Txt">
                                {
                                  item?.content?.map((subItem, index) => {
                                    if (index === 1) {
                                      return (
                                          <a key={key + '_' + index} href={item?.link ?? ''}>{subItem ?? ''} &nbsp;</a>
                                      )
                                    } else {
                                      return (
                                          <span key={key + '_' + index}>{subItem ?? ''} &nbsp;</span>
                                      )
                                    }
                                  })
                                }
                              </div>
                            </div>
                        )
                      })
                      :
                      <>
                        <div className="Base_Align_C Base_Fade_Animation">
                          <CircularProgressbar className="progress-bar-animated" value={value1} maxValue={1} text={`${value1 * 100}%`} />
                          <div className="Productivity_Title_Txt">EXCESS MORTALITY RATE DUE TO COVID-19</div>
                          <div className="Desc_Txt">
                            Excess mortality — the deviation of deaths attributable to COVID-19 as compared to expected deaths based on previous years — is estimated at
                            <a href="https://ourworldindata.org/excess-mortality-covid"> up to 200% </a>
                            in some countries since March 2020.
                          </div>
                        </div>

                        <div className="Base_Align_C Base_Fade_Animation">
                          <CircularProgressbar className="progress-bar-animated" value={value2} maxValue={1} text={`${value2 * 100}%`} />
                          <div className="Productivity_Title_Txt">COUNTRIES REPORTING COVID-19 CASES</div>
                          <div className="Desc_Txt">
                            The entire globe is now impacted by COVID-19, with 96% — or
                            <a href="https://coronavirus.jhu.edu/map.html"> 188 out of 195 </a>
                            — of countries reporting cases.
                          </div>
                        </div>

                        <div className="Base_Align_C Base_Fade_Animation">
                          <CircularProgressbar className="progress-bar-animated" value={value3} maxValue={1} text={`${value3 * 100}%`} />
                          <div className="Productivity_Title_Txt">INCREASE IN AVERAGE DAILY COVID-19 CASES</div>
                          <div className="Desc_Txt">
                            From July 15 – August 15, 2020, well over
                            <a href="https://www.statista.com/statistics/1103046/new-coronavirus-covid19-cases-number-worldwide-by-day/"> 200,000 new cases of COVID-19 </a>
                            were reported daily across the world. These new case counts have doubled since early June, when at least 100,000 new global cases were reported daily.
                          </div>
                        </div>
                      </>
                }
              </div>
            </div>
          </Fade>
        </div>

        {
          pageData?.help_section ?
              <FindOut
                  title={pageData?.help_section?.title ?? ''}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
              />
              :
              <FindOut
                  title='HOW WE ARE ADDRESSING COVID-19'
                  desc='The KENZEN solution helps detect invisible threats, such as COVID-19 and other infectious diseases.'
                  btnName='Find Out More'
                  link='/sales'
              />
        }

        <WorkerSlider
            container={pageData?.slider_section ?? ''}
        />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default AddressingCovid;
