import React from "react";
import {useNavigate} from "react-router-dom";
import "./index.scss";

const Footer = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    (function (w, d, s, o, f, js, fjs) {
      w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
      js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'gdprBadge', 'https://cdn.gdprlocal.com/static/widget.js'));
    window.gdprBadge('init',{element: document.getElementById('gdprVerifier'), verificationId: 'WXR7QJT'});
  }, []);

  return (
      <div className="Footer_Body">
        <div className="Container">
          <span className="Year_Txt">© {new Date().getFullYear()} KENZEN</span>
          <div>
            <div className="Policy_Link Base_Mouse_Cursor" onClick={() => navigate("/website-privacy-policy")}>WEBSITE PRIVACY POLICY</div>
            <div className="Policy_Link Base_Mouse_Cursor" onClick={() => navigate("/kenzen-solution-privacy-policy/")}>KENZEN SOLUTION PRIVACY POLICY</div>
          </div>

          <div className="Social_Icons_Body">
            <a href="https://www.facebook.com/kenzenwear/" className="Social_Icon Facebook" title="Follow on Facebook" target="_blank" rel="noreferrer">
              <span>Follow</span>
            </a>
            <a href="https://twitter.com/kenzeninc" className="Social_Icon Twitter" title="Follow on Twitter" target="_blank" rel="noreferrer">
              <span>Follow</span>
            </a>
            <a href="https://www.linkedin.com/company/kenzen-wear/" className="Social_Icon Linkedin" title="Follow on Linkedin" target="_blank" rel="noreferrer">
              <span>Follow</span>
            </a>
            <a href="https://www.instagram.com/kenzeninc/" className="Social_Icon Instagram" title="Follow on Instagram" target="_blank" rel="noreferrer">
              <span>Follow</span>
            </a>
          </div>
          <div id="gdprVerifier">
          </div>
        </div>
      </div>
  );
};
export default Footer;
