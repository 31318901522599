import React from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./index.scss";
import ScheduleToday from "../../components/ScheduleToday";
import Press from "../../components/Press";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import {WorkerSlider} from "../../components/CustomSlider";
import GuardingMonitoring from "../../components/GuardingMonitoring";
import Industries from "../../components/Industries";
import {Fade} from "../../components/Fade";

import {
  HEALTH_MORNING_DATA,
  HEALTH_MORNING_WHO,
  KENZEN_MORNITORS,
  HEALTH_MORNING_BENEFITS,
} from "../../constants";

import KenWorkerImg from "../../assets/images/soultion/KEN-worker-with-device-IMG_5835.jpg";
import ChartImg from "../../assets/images/soultion/CHART0TRANS-01-1.gif";
import {useDataContext} from "../../providers/DataProvider";
import HeaderBgImg from "../../assets/images/home/miner.jpg";

const HealthMonitoring = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="HealthMonitoring_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>

        <ScheduleToday/>

        {
          pageData?.hero_section ?
              <GuardingMonitoring
                  title={pageData?.hero_section?.title ?? ""}
                  subTitle={pageData?.hero_section?.sub_title ?? ""}
                  desc={pageData?.hero_section?.desc ?? []}
              />
              :
              <GuardingMonitoring
                  title="GUARDING YOUR GREATEST ASSET"
                  subTitle="END-TO-END HEALTH & SAFETY MONITORING"
                  desc={[
                    "When injury and illness strike, your most valuable asset suffers. Keep your workforce safe with continuous, end-to-end detection that predicts and prevents serious health conditions.",
                    "KENZEN provides heat and safety monitoring of key physiological indicators for each worker, such as core body temperature, heart rate, and exertion levels."
                  ]}
              />
        }

        <Fade>
          <div className="Et_Pb_Section4">
            <div className="Et_Pb_Container">
              <div className="Et_Pb_Txt_Inner">{pageData?.platform_section?.title ?? 'CONTINUOUS MONITORING PLATFORM'}</div>
              <div className="Et_Pb_Txt_Desc">
                {pageData?.platform_section?.desc ?? 'A complete safety monitoring platform, the KENZEN solution deploys across your workforce — via integrated worker devices, mobile apps, team dashboards, and enterprise software.'}
              </div>

              <div className="Grid2 Base_Fade_Animation">
                {
                  (pageData?.platform_image_text ?? HEALTH_MORNING_DATA)?.map((item, key) => {
                    return (
                        <div key={key} className="Base_Align_C">
                          <div className="Title_Txt">{item?.title ?? ''}</div>
                          <div className="Desc_Txt">{item?.desc ?? ''}</div>
                          <img src={item?.image} alt="img" />
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Fade>

        <Fade>
          <div className="Et_Pb_Section5">
            <div className="Et_Pb_Container">
              <div className="Et_Pb_Txt_Inner">{pageData?.benefits_section?.title ?? 'Who Benefits'}</div>
              <div className="Grid3 Base_Fade_Animation">
                {
                  (pageData?.benefits_section?.content ?? HEALTH_MORNING_WHO)?.map((item, key) => {
                    return (
                        <div key={key}>
                          <div className="Title_Txt">{item?.name}</div>
                          <div className="Content_Txt">{item?.desc}</div>
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Fade>

        <Fade>
          <div className="Et_Pb_Section5">
            <div className="Et_Pb_Container">
              <div className="Et_Pb_Txt_Inner">WHAT KENZEN MONITORS</div>

              <div className="Monitor_Grid3 Base_Fade_Animation">
                {
                  pageData?.icon_text?.length > 0 ?
                      <div>
                        <div className="Monitor_Name">{pageData?.icon_text[0].desc}</div>
                        <img src={pageData?.icon_text[0].image} className="Icon_Kenzen" alt="what" />
                        {
                          pageData?.icon_text?.length > 1 && (
                              <>
                                <div className="Monitor_Name">{pageData?.icon_text[1].desc}</div>
                                <img src={pageData?.icon_text[1].image} className="Icon_Kenzen" alt="what" />
                              </>
                          )
                        }
                      </div>
                      :
                      <div>
                        <div className="Monitor_Name">{KENZEN_MORNITORS[0].desc}</div>
                        <img src={KENZEN_MORNITORS[0].image} className="Icon_Kenzen" alt="what" />
                        <div className="Monitor_Name">{KENZEN_MORNITORS[1].desc}</div>
                        <img src={KENZEN_MORNITORS[1].image} className="Icon_Kenzen" alt="what" />
                      </div>
                }

                <img src={pageData?.monitor_bg ?? KenWorkerImg} className="Kenzen_Img" alt='ken worker' />

                {
                  pageData?.icon_text?.length > 2 ?
                      <div>
                        <div className="Monitor_Name">{pageData?.icon_text[2].desc}</div>
                        <img src={pageData?.icon_text[2].image} className="Icon_Kenzen" alt="what" />
                        {
                          pageData?.icon_text?.length > 3 && (
                              <>
                                <div className="Monitor_Name">{pageData?.icon_text[3].desc}</div>
                                <img src={pageData?.icon_text[3].image} className="Icon_Kenzen" alt="what" />
                              </>
                          )
                        }
                      </div>
                      :
                      <div>
                        <div className="Monitor_Name">{KENZEN_MORNITORS[2].desc}</div>
                        <img src={KENZEN_MORNITORS[2].image} className="Icon_Kenzen" alt="what" />
                        <div className="Monitor_Name">{KENZEN_MORNITORS[3].desc}</div>
                        <img src={KENZEN_MORNITORS[3].image} className="Icon_Kenzen" alt="what" />
                      </div>
                }
              </div>
            </div>
          </div>
        </Fade>

        <Fade>
          <div className="Et_Pb_Section5">
            <div className="Et_Pb_Container Kenzen_Container">
              <div className="Grid2 Base_Fade_Animation">
                {
                  (pageData?.monitors_section ?? HEALTH_MORNING_BENEFITS)?.map((item, key) => {
                    return (
                        <div key={key} className="">
                          <div className="Et_Pb_Txt_Inner Align_L">{item?.title}</div>
                          <ul>
                            {
                              item?.content?.map((subItem, index) => {
                                return (
                                    <li key={key + '_' + index}>
                                      {subItem}
                                    </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Fade>

        <Industries
            bgImage={pageData?.author_section_bg}
            title={pageData?.author_section?.title ?? 'END-TO-END HEALTH & SAFETY MONITORING'}
            authorDate={pageData?.author_section?.desc ?? 'by Christa Bianchi | Jun 2, 2020 |'}
        />

        <div className="Et_Pb_Section6">
          <Fade>
            <div className="Et_Pb_Container">
              <div className="Productivity_Grid3">
                <div className="Base_Align_C Base_Fade_Animation">
                  {
                    pageData?.chart_section?.content?.length > 0 && (
                        <>
                          <CircularProgressbar className="progress-bar-animated" value={(pageData?.chart_section?.content[0]?.percentage ?? 0) / 100} maxValue={1} text={`${pageData?.chart_section?.content[0]?.percentage ?? 0}%`} />

                          <div className="Productivity_Title_Txt">{pageData?.chart_section?.content[0]?.title ?? 'Productivity Loss'}</div>
                          <div className="Desc_Txt">
                            {
                              pageData?.chart_section?.content[0]?.desc?.length > 0 ?
                                  pageData?.chart_section?.content[0]?.desc?.map((item, key) => {
                                    if (key === 1) {
                                      return (
                                          <a key={key} href={pageData?.chart_section?.content[0]?.link ?? "https://link.springer.com/chapter/10.1007/978-3-030-27805-2_12"} target="_blank" rel="noreferrer">
                                            {item ?? ''} &nbsp;
                                          </a>
                                      )
                                    } else {
                                      return (
                                          <span key={key}>
                                            {item ?? ''} &nbsp;
                                          </span>
                                      )
                                    }
                                  })
                                  :
                                  <>
                                    There is a
                                    <a href="https://link.springer.com/chapter/10.1007/978-3-030-27805-2_12" target="_blank" rel="noreferrer">
                                      26% loss in productivity
                                    </a>
                                    when workers labor in 95 degrees Fahrenheit.
                                  </>
                            }
                          </div>
                        </>
                    )
                  }
                </div>

                <div className="Base_Align_C">
                  <div className="Critical_Txt">{pageData?.chart_secton?.title ?? 'Why kenzen is Critical'}</div>
                  <img src={ChartImg} alt="chart img" />
                  <div className="Desc_Txt">
                    {pageData?.chart_secton?.desc ?? 'The number of construction worker days in dangerous heat is estimated to almost triple by 2050.'}
                  </div>
                </div>

                <div className="Base_Align_C Base_Fade_Animation">
                  {
                    pageData?.chart_section?.content?.length > 1 && (
                        <>
                          <CircularProgressbar className="progress-bar-animated" value={(pageData?.chart_section?.content[1]?.percentage ?? 0) / 100} maxValue={1} text={`${pageData?.chart_section?.content[1]?.percentage ?? 0}%`} />

                          <div className="Productivity_Title_Txt">{pageData?.chart_section?.content[1]?.title ?? 'HEAT EXPOSURE & RISK'}</div>
                          <div className="Desc_Txt">
                            {
                              pageData?.chart_section?.content[1]?.desc?.length > 0 ?
                                  pageData?.chart_section?.content[1]?.desc?.map((item, key) => {
                                    if (key === 1) {
                                      return (
                                          <a key={key} href={pageData?.chart_section?.content[1]?.link ?? "https://www.nbcnews.com/news/us-news/military-s-climate-change-problem-blistering-heat-killing-soldiers-during-n1032546"} target="_blank" rel="noreferrer">
                                            {item ?? ''} &nbsp;
                                          </a>
                                      )
                                    } else {
                                      return (
                                          <span key={key}>
                                            {item ?? ''} &nbsp;
                                          </span>
                                      )
                                    }
                                  })
                                  :
                                  <>
                                    Heat exposure is a significant, growing threat, with an
                                    <a href="https://www.nbcnews.com/news/us-news/military-s-climate-change-problem-blistering-heat-killing-soldiers-during-n1032546" target="_blank" rel="noreferrer">
                                      increase of almost 60%
                                    </a>
                                    in heat stroke and exhaustion cases in the military since 2008.
                                  </>
                            }
                          </div>
                        </>
                    )
                  }
                </div>
              </div>
            </div>
          </Fade>
        </div>

        {
          pageData?.help_section ?
              <FindOut
                  title={pageData?.help_section?.title ?? ''}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
              />
              :
              <FindOut
                  title='HOW WE ARE ADDRESSING COVID-19'
                  desc='The KENZEN solution helps detect invisible threats, such as COVID-19 and other infectious diseases.'
                  btnName='Find Out More'
                  link='/sales'
              />
        }

        <WorkerSlider
            container={pageData?.slider_section ?? ''}
        />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default HealthMonitoring;
