import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import PiwikPro from '@piwikpro/react-piwik-pro';

import {Provider} from 'react-redux';
import {store} from "./redux/storeConfig/store";
import './index.css';

PiwikPro.initialize('17b75f1f-6e0f-4ab5-877a-afab34490cf2', 'https://kenzen.piwik.pro');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App/>
      </Provider>
    </React.StrictMode>
);
reportWebVitals();
