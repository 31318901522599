import {
	SHOW_SPINNING,
	ERR_MESSAGE,
} from "../actions/types"

const initialState = {
	spinning: '',
	msgError: false,
};

export default function registerFunction (state = initialState, action){
	switch(action.type){
		case SHOW_SPINNING: return { ...state, spinning: action.payload, };
		case ERR_MESSAGE: return { ...state, msgError: action.payload, };
		default: return state;
	}
}
