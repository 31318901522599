import React from "react";
import {Link} from "react-router-dom";
import "./index.scss";
import Sidebar from "../../components/Sidebar";
import {useDataContext} from "../../providers/DataProvider";

const NewsDetail = () => {
  const {
    newsDetail,
  } = useDataContext();

  return (
      <div className="NewsDetail_Body">
        <div className="Et_Pb_Container">
          <div className="Grid2">
            <div className="Contents">
              <div className="Title_Txt">
                {newsDetail?.title ?? ''}
              </div>
              <div className="Author_Txt">
                {
                  newsDetail?.authorName && (
                      <Link to={'/news-blog/author?id=' + (newsDetail?.authorId ?? '')}>by {newsDetail?.authorName ?? ''}</Link>
                  )
                }

                {
                  newsDetail?.date && (
                      <>
                        &nbsp; | &nbsp; {new Date(newsDetail?.date).toLocaleString("en-US", { year: "numeric", month: "short", day: "2-digit"})}
                      </>
                  )
                }

                {
                  newsDetail?.categoryNames && (
                      <Link to={'/news-blog/author?id=' + (newsDetail?.authorId ?? '')}> &nbsp; | &nbsp;{newsDetail?.categoryNames ?? ''}</Link>
                  )
                }
              </div>
              {
                newsDetail?.thumbnail && (
                    <img src={newsDetail?.thumbnail} className="thumbnail_img" alt='thumbnail' />
                )
              }
              {
                newsDetail?.content &&
                <div className="NewsContainer" dangerouslySetInnerHTML={{__html: newsDetail?.content}} />
              }
            </div>

            <Sidebar />
          </div>
        </div>
      </div>
  )
};
export default NewsDetail;