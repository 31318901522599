import React from "react";
import {useNavigate} from "react-router-dom";
import ReactPlayer from 'react-player';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./index.scss";

import Affiliations from "../../components/Affiliations";
import Press from "../../components/Press";
import ContactSales from "../../components/ContactSales";
import FindOut from "../../components/FindOut";
import {WorkerSlider} from "../../components/CustomSlider";
import {Fade} from "../../components/Fade";
import {useDataContext} from "../../providers/DataProvider";

import {
  CONTACT_SALES_TODAY_INIT,
  PROTECTION_TEXT,
} from "../../constants";
import PicImg from "../../assets/images/home/PikPng.com_earth-png_609583-300x98.png";
import KenzenDeviceImg from "../../assets/images/home/Kenzen-Device-01.26-1.png";
import HeaderBgImg from "../../assets/images/home/miner.jpg";

const Home = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  const navigate = useNavigate();

  return (
      <div className="Home_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>

        <div className="Et_Top_Container">
          {
            pageData?.hero_section ?
                <div className="Base_Fade_Animation">
                  <div className="Title_Txt">{pageData?.hero_section?.name ?? ''}</div>
                  {
                    pageData?.hero_section?.desc?.map((item, key) => {
                      return (
                          <div key={key} className={`Desc_Txt ${key !== 0 ? 'Pt16' : ''}`}>{item ?? ''}</div>
                      )
                    })
                  }

                  {
                    pageData?.hero_section?.button && (
                        <div
                            className="Base_Et_Pb_Button"
                            onClick={() => pageData?.hero_section?.button?.link ? navigate(pageData?.hero_section?.button?.link) : null}
                        >
                          <span className="Base_Txt_Ellipse2">{pageData?.hero_section?.button?.name ?? ''}</span>
                        </div>
                    )
                  }
                </div>
                :
                <div className="Base_Fade_Animation">
                  <div className="Title_Txt">PROTECTING THE INDUSTRIAL ATHLETE</div>
                  <div className="Desc_Txt">KENZEN’s continuous safety monitoring solutions harness the power of physiology data to predict and prevent heat injury, illness, and death from heat on worksites. When workers are safe, everyone benefits.</div>
                  <div className="Desc_Txt Pt16">The standard is KENZEN.</div>

                  <div
                      className="Base_Et_Pb_Button"
                      onClick={() => navigate('/superior-science')}
                  >
                    <span className="Base_Txt_Ellipse2">OUR GROUNDBREAKING HEAT SCIENCE</span>
                  </div>
                </div>
          }
        </div>

        <section className="Et_Pb_Section">
          {
            pageData?.video_section ?
                <Fade>
                  <div className="Contents">
                    <div className="Grid2">
                      <div>
                        <div className="Think_Txt">{pageData?.video_section?.title ?? ''}</div>
                        {
                          pageData?.video_section?.image && (
                              <div className="Pic_Img Base_Justify_Center">
                                <img src={pageData?.video_section?.image} className="Img_W156" alt="img pic" />
                              </div>
                          )
                        }
                      </div>

                      <div>
                        <ReactPlayer
                            url={pageData?.video_link || 'https://www.youtube-nocookie.com/embed/C6OVzFxPVig?feature=oembed'}
                            width='100%'
                            height='100%'
                            className="Video_Img"
                            playing={false}
                            controls
                        />
                      </div>
                    </div>
                  </div>
                </Fade>
                :
                <Fade>
                  <div className="Contents">
                    <div className="Grid2">
                      <div>
                        <div className="Think_Txt">Kenzen safety technology featured with customer, EGA, on Think Big.</div>
                        <div className="Pic_Img Base_Justify_Center">
                          <img src={PicImg} className="Img_W156" alt="img pic" />
                        </div>
                      </div>

                      <div>
                        <ReactPlayer
                            url={'https://www.youtube-nocookie.com/embed/C6OVzFxPVig?feature=oembed'}
                            width='100%'
                            height='100%'
                            className="Video_Img"
                            playing={false}
                            controls
                        />
                      </div>
                    </div>
                  </div>
                </Fade>
          }
        </section>

        <section className="Et_Section_Regular">
          <Fade>
            <div className="Contents">
              <div className="Pt_Pb_3 All_Txt">
                {pageData?.middle_section?.title ?? 'Safeguarding the Protection of All'}
              </div>

              <div className="Pt_Pb_3 Grid3">
                {
                  (pageData?.middle_section?.content || PROTECTION_TEXT)?.map((item, key) => {
                    return (
                        <div key={key}>
                          <div className="Worker_Title Base_Align_C">{item?.name ?? ''}</div>
                          <div className="Et_Pb_Text Base_Align_C">{item?.desc ?? ''}</div>
                        </div>
                    )
                  })
                }
              </div>

              <div className="Pt_Pb_3 Grid3">
                <div className="Base_Margin_Auto">
                  <div className="Base_Et_Pb_Button">
                    <span className="Base_Txt_Ellipse2">Our Monitoring Platform</span>
                  </div>
                </div>
                <img src={pageData?.protection_img || KenzenDeviceImg} className="Kenzen_Img" alt="kenzen device" />
                <div className="Base_Margin_Auto">
                  <div className="Base_Et_Pb_Button">
                    <span className="Base_Txt_Ellipse2">Safety Tech In Development</span>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </section>

        <WorkerSlider
            container={pageData?.slider_section ?? ''}
        />

        {
          pageData?.help_section ?
              <FindOut
                  title={pageData?.help_section?.title ?? ''}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
              />
              :
              <FindOut
                  title='HOW WE ARE ADDRESSING COVID-19'
                  desc='The KENZEN solution helps detect invisible threats, such as COVID-19 and other infectious diseases.'
                  btnName='Find Out More'
                  link='/sales'
              />
        }

        <ContactSales
            type="contact_sales_today"
            title={pageData?.submit_section?.title ?? "CONTACT SALES TODAY"}
            description={pageData?.submit_section?.desc ?? "KENZEN Enterprise solution for heat safety is available now. Contact us to get started."}
            initValues={pageData?.submit_section?.init_forms ?? CONTACT_SALES_TODAY_INIT}
        />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};

export default Home;
