import React from "react";
import {Fade} from "../Fade";
import "./index.scss";

const GuardingMonitoring = (props) => {
  return (
      <Fade>
        <div className="GuardingMonitoring_Body Base_Fade_Animation">
          <div className="Txt_Content">
            {
              props?.downloadLink ?
                  <a href={props?.downloadLink} target="_blank" rel="noreferrer">
                    <div className="Title_Txt">{props?.title ?? ''}</div>
                  </a>
                  :
                  <div className="Title_Txt">{props?.title ?? ''}</div>
            }

            <div className="Sub_Title_Txt">{props?.subTitle ?? ''}</div>
            {
              props?.desc?.map((item, key) => {
                return (
                    <div key={key} className={`Desc_Txt ${props?.type ?? ''}`}>
                      <span className="Base_Txt_Ellipse6">{item ?? ''}</span>
                    </div>
                )
              })
            }

            {
              props?.downloadLink && (
                  <a href={props?.downloadLink} target="_blank" rel="noreferrer">
                    <div className="Download_Btn">{"KENZEN'S HEAT SCIENCE PDF"}</div>
                  </a>
              )
            }
          </div>
        </div>
      </Fade>
  )
};
export default GuardingMonitoring;