import React from 'react';
import "./index.scss";

export const Fade = ({children}) => {
  /*const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef(null);
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    if (domRef.current) {
      observer.observe(domRef.current);
    }
    return () => {
      if (domRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(domRef.current);
      }
    };
  }, []);*/
  return (
      /*<div ref={domRef} className={`Fade_Body ${isVisible ? 'isVisible' : ''}`}>
        {children}
      </div>*/
    <>
      {children}
    </>
  );
};
