import React, {Suspense, useRef} from "react";
import {BrowserRouter, Routes} from "react-router-dom";
import {Navigate, Route} from "react-router";
import {useWindowScroll} from "../utils/hooks";

import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Loader from "../components/Loader";
import Home from "../routes/Home";
import NewsBlog from "../routes/NewsBlog";
import SuperiorScience from "../routes/SuperiorScience";

import Sales from "../routes/Sales";
// import Jobs from "../routes/Jobs";
// import PressInquiries from "../routes/PressInquiries";
// import RAndD from "../routes/RAndD";
// import InvestorInquiries from "../routes/InvestorInquiries";
import Support from "../routes/Support";
import HealthMonitoring from "../routes/HealthMonitoring";
import BenefittingIndustries from "../routes/BenefittingIndustries";
import TechDevelopment from "../routes/TechDevelopment";
import KeepsEveryOneWorking from "../routes/KeepsEveryOneWorking";
import LeadershipTeamBoard from "../routes/LeadershipTeamBoard";
import AddressingCovid from "../routes/AddressingCovid";
import SafetyTechSmart from "../routes/SafetyTechSmart";
import SolutionPrivacyPolicy from "../routes/SolutionPrivacyPolicy";
import NewsDetail from "../routes/NewsBlog/newsDetail";
import AuthorDetail from "../routes/NewsBlog/authorDetail";
import WebsitePrivacyPolicy from "../routes/WebsitePrivacyPolicy";
import TermsOfUse from "../routes/TermsOfUse";

import {DataProvider} from "../providers/DataProvider";
import {SubmitProvider} from "../providers/SubmitProvider";

const Router = () => {
  const refScroll = useRef(null);

  const onTopScroll = () => {
    refScroll.current.scrollIntoView({behavior: "smooth"});
  };

  return (
      <Suspense fallback={<Loader/>}>
        <BrowserRouter basename="/">

          <div id="bodyId" ref={refScroll}/>
          {
            useWindowScroll()?.scrollY > 400 && (
                <div className="Et_Pb_Scroll" onClick={() => onTopScroll()} />
            )
          }

          <div style={{position: 'relative'}}>
            <Header/>

            <Routes>
              <Route
                  path="/"
                  element={
                    <DataProvider pageNo={1}>
                      <SubmitProvider>
                        <Home/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />

              {/* The Solution */}
              <Route
                  path="/end-to-end-health-and-safety-monitoring"
                  element={
                    <DataProvider pageNo={2}>
                      <SubmitProvider>
                        <HealthMonitoring/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/benefitting-industries"
                  element={
                    <DataProvider pageNo={3}>
                      <SubmitProvider>
                        <BenefittingIndustries/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/safety-tech-in-development"
                  element={
                    <DataProvider pageNo={4}>
                      <SubmitProvider>
                        <TechDevelopment/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/safety-keeps-everyone-working"
                  element={
                    <DataProvider pageNo={5}>
                      <SubmitProvider>
                        <KeepsEveryOneWorking/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />

              <Route
                  path="/superior-science"
                  element={
                    <DataProvider pageNo={6}>
                      <SubmitProvider>
                        <SuperiorScience/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />

              {/* About Us */}
              <Route
                  path="/leadership-team-board"
                  element={
                    <DataProvider pageNo={7}>
                      <SubmitProvider>
                        <LeadershipTeamBoard/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/how-we-are-addressing-covid-19"
                  element={
                    <DataProvider pageNo={8}>
                      <SubmitProvider>
                        <AddressingCovid/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/iiot-safety-tech-smart-ppe"
                  element={
                    <DataProvider pageNo={9}>
                      <SubmitProvider>
                        <SafetyTechSmart/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/kenzen-solution-privacy-policy"
                  element={
                    <DataProvider pageNo={10}>
                      <SolutionPrivacyPolicy/>
                    </DataProvider>
                  }
              />
              <Route
                  path="/website-privacy-policy"
                  element={
                    <DataProvider pageNo={11}>
                      <WebsitePrivacyPolicy/>
                    </DataProvider>
                  }
              />
              <Route
                  path="/terms-of-use"
                  element={
                    <DataProvider pageNo={21}>
                      <TermsOfUse/>
                    </DataProvider>
                  }
              />

              {/* Contact Us */}
              <Route
                  path="/sales/:slug?"
                  element={
                    <DataProvider pageNo={12}>
                      <SubmitProvider>
                        <Sales/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              {/*<Route
                  path="/press-inquiries/:slug?"
                  element={
                    <DataProvider pageNo={13}>
                      <SubmitProvider>
                        <PressInquiries/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/rd/:slug?"
                  element={
                    <DataProvider pageNo={14}>
                      <SubmitProvider>
                        <RAndD/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/jobs/:slug?"
                  element={
                    <DataProvider pageNo={15}>
                      <SubmitProvider>
                        <Jobs/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/investor-inquiries/:slug?"
                  element={
                    <DataProvider pageNo={16}>
                      <SubmitProvider>
                        <InvestorInquiries/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />*/}
              <Route
                  path="/support/:slug?"
                  element={
                    <DataProvider pageNo={17}>
                      <SubmitProvider>
                        <Support/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />

              <Route
                  path="/news-blog/:slug?"
                  element={
                    <DataProvider pageNo={18}>
                      <SubmitProvider>
                        <NewsBlog/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/news-blog/detail/:id?"
                  element={
                    <DataProvider pageNo={19}>
                      <SubmitProvider>
                        <NewsDetail/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="/news-blog/author/:id?"
                  element={
                    <DataProvider pageNo={20}>
                      <SubmitProvider>
                        <AuthorDetail/>
                      </SubmitProvider>
                    </DataProvider>
                  }
              />
              <Route
                  path="*"
                  element={<Navigate to="/" replace />}
              />
            </Routes>

            <Footer/>
          </div>
        </BrowserRouter>
      </Suspense>
  )
};
export default Router;
