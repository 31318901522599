import axios from "axios";
import {apiBaseUrl as baseUrl, apiToken} from "../config";

export const instance = axios.create({
  baseURL: baseUrl,
  timeout: 20000,
});

// Request interceptor for API calls
instance.interceptors.request.use(
    async config => {
      config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${apiToken}`,
      };
      return config;
    },
    error => {
      return Promise.reject(error);
    });

function get(url, token, customHeaders) {
  let headers = {};
  if (customHeaders) {
    headers = customHeaders;
  }
  if (token) {
    headers = {
      ...headers,
      "Authorization": `Bearer ${token}`,
    };
  }
  return new Promise((resolve, reject) => {
    instance.get(url, {
      headers: headers,
    })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        })
  });
}

// eslint-disable-next-line no-unused-vars
function post(url, body, token, customHeaders, cancelToken) {
  let headers = {};
  if (customHeaders) {
    headers = customHeaders;
  }
  if (token) {
    headers = {
      ...headers,
      "Authorization": `Bearer ${token}`,
    };
  }
  return new Promise((resolve, reject) => {
    instance.post(url, body, {
      headers: headers,
      cancelToken: cancelToken,
    })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        })
  });
}

/**
 * Posts by pagination
 * @param keyword
 * @param page
 * @param pageSize
 * @param categoryId
 * @returns {Promise<unknown>}
 */
export const queryNewsBlog = (keyword, page, pageSize, categoryId) => {
  return get(`api/posts?sort[0]=date%3Adesc&sort[1]=title&_q=${keyword ?? ''}&filters[$and][0][show_type][$ne]=portal&filters[categories][id][${categoryId ? '$in' : '$containsi'}]=${categoryId ?? ''}&pagination[page]=${page ?? 1}&pagination[pageSize]=${pageSize ?? 10}&populate=*`);
};

/**
 * Post By Blog Id
 * @returns {Promise<unknown>}
 * @param newsId
 */
export const queryNewsBlogDetail = (newsId) => {
  return get(`api/posts?filters[id][$eq]=${newsId ?? ''}&filters[$and][0][show_type][$ne]=portal&populate=*`);
};

/**
 * Total Categories
 * @returns {Promise<unknown>}
 */
export const queryCategories = () => {
  return get(`api/categories`);
};

/**
 * Total Categories
 * @returns {Promise<unknown>}
 */
export const queryNewsBlogPerAuthor = (page, pageSize, authorId) => {
  return get(`api/posts?sort[0]=date%3Adesc&sort[1]=title&filters[$and][0][show_type][$ne]=portal&filters[admin_user][id][${authorId ? '$in' : '$containsi'}]=${authorId ?? ''}&pagination[page]=${page ?? 1}&pagination[pageSize]=${pageSize ?? 5}&populate=*`);
};

/**
 * Getting enumerations
 */
export const queryGetEnumerations = () => {
  return get(`api/content-type-builder/content-types/api::submit.submit`);
};

/**
 * Submitting
 */
export const queryPostContact = (data) => {
  return post(`api/submits`, {data: data});
};

/**
 * Uploading file
 */
export const queryPostUploadFile = (data) => {
  return post(`api/upload/`, data);
};

export const queryCheckRecaptcha = async (token) => {
  await axios.post(`https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECURET_KEY}&response=${token}`)
      .then(res => {
        console.log(res + ': recaptcha result');
        return res?.status === 200;
      }).catch(e => {
        console.log(e.toString() + ': recaptcha error');
        return false;
      })
};

/**
 * page data
 */
export const queryPageData = (uri) => {
  return get(`api${uri}?populate=deep`);
};

/**
 * Press Section
 */
export const queryPress = () => {
  return get(`api/press-sections?populate=*`);
};

/**
 * Affiliation Section
 */
export const queryAffiliation = () => {
  return get(`api/affiliations-sections?populate=*`);
};

/**
 * Investor Section
 */
export const queryInvestor = () => {
  return get(`api/investor-sections?populate=*`);
};

/**
 * Location Section
 */
export const queryLocation = () => {
  return get(`api/location-sections?populate=*`);
};

export const queryMember = () => {
  return get(`api/members?populate=*`);
};

export const queryDirector = () => {
  return get(`api/directors?populate=*`);
}
