import React from "react";
import {useSelector} from "react-redux";
import Loader from "./components/Loader";
import Router from "./router";
import './assets/scss/styles.scss';

const App = () => {
  const store = useSelector(state => state);

  return (
      <div>
        {
          store?.registers?.spinning && (
              <Loader/>
          )
        }
        <Router/>
      </div>
  );
};

export default App;
