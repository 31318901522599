import React from "react";
import {Link} from "react-router-dom";

import "./index.scss";
import ScheduleToday from "../../components/ScheduleToday";
import GuardingMonitoring from "../../components/GuardingMonitoring";
import {Fade} from "../../components/Fade";

import {
  PREVIEWED_PAPER_DATA,
  ANALYZES_DATA,
} from "../../constants";

import Bg_Img1 from "../../assets/images/soultion/GettyImages-102285500-renewable-1920.jpg";
import Bg_Img2 from "../../assets/images/soultion/christopher-burns-368617-unsplash-ready-2.jpg";
import DownloadLink from "../../assets/images/science/Kenzen_Heat_Safety_Science.pdf";
import REVIEW_IMG1 from "../../assets/images/science/K-angled.jpg";
import REVIEW_IMG2 from "../../assets/images/science/Summary-Page-Public-e1616525709872.png";
import {useDataContext} from "../../providers/DataProvider";

const SuperiorScience = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="Superior_Science_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? Bg_Img1) + ')'}} />
        </div>

        <ScheduleToday/>

        {
          pageData?.hero_section ?
              <GuardingMonitoring
                  title={pageData?.hero_section?.title ?? ""}
                  subTitle={pageData?.hero_section?.sub_title ?? ""}
                  downloadLink={DownloadLink}
                  desc={pageData?.hero_section?.desc ?? []}
              />
              :
              <GuardingMonitoring
                  title="SUPERIOR SCIENCE = SUPERIOR SAFETY"
                  subTitle=""
                  downloadLink={DownloadLink}
                  desc={[
                    "Keeping your employees safe from heat injury is becoming increasingly important as the earth warms. KENZEN’s scientific rigor, massive data set, and collaborations with heat scientists result in the highest level of integrity and safety available. And our continuous monitoring platform measures core temperature using non-invasive methods. It’s KENZEN safety, rooted in superior science."
                  ]}
              />
        }

        <div className="Et_Pb_Section1">
          <Fade>
            {
              pageData?.reviewed_section?.length ?
                  <div className="Et_Pb_Row2">
                    {
                      pageData?.reviewed_section?.length > 0 && (
                          <>
                            <div className="Base_Align_C">
                              <div className="Icon_ETmodules">{pageData?.reviewed_section[0]?.icon_txt ?? ''}</div>
                              <div className="Title_Txt">{pageData?.reviewed_section[0]?.title ?? ''}</div>
                              <div className="Content_Txt">{pageData?.reviewed_section[0]?.desc ?? ''}</div>
                            </div>
                            <div>
                              <a href={DownloadLink} rel="noreferrer" target="_blank">
                                <div className="Btn_Read_Paper">Read Our Peer-Reviewed Paper</div>
                              </a>
                              <img src={pageData?.viewed_imgs?.length > 0 ? pageData?.viewed_imgs[0] : REVIEW_IMG1} alt="k-angled" />
                            </div>
                          </>
                      )
                    }
                    {
                      pageData?.reviewed_section?.length > 1 && (
                          <div className="Base_Align_C">
                            <div className="Icon_ETmodules">{pageData?.reviewed_section[1]?.icon_txt ?? ''}</div>
                            <div className="Title_Txt">{pageData?.reviewed_section[1]?.title ?? ''}</div>
                            <div className="Content_Txt">{pageData?.reviewed_section[1]?.desc ?? ''}</div>
                          </div>
                      )
                    }
                    {
                      pageData?.reviewed_section?.length > 2 && (
                          <>
                            <div className="Base_Align_C">
                              <div className="Icon_ETmodules">{pageData?.reviewed_section[2]?.icon_txt ?? ''}</div>
                              <div className="Title_Txt">{pageData?.reviewed_section[2]?.title ?? ''}</div>
                              <div className="Content_Txt">{pageData?.reviewed_section[2]?.desc ?? ''}</div>
                            </div>
                            <div>
                              <img src={pageData?.viewed_imgs?.length > 1 ? pageData?.viewed_imgs[1] : REVIEW_IMG2} alt="k-angled" />
                            </div>
                          </>
                      )
                    }
                    {
                      pageData?.reviewed_section?.length > 3 && (
                          <div className="Base_Align_C">
                            <div className="Icon_ETmodules">{pageData?.reviewed_section[3]?.icon_txt ?? ''}</div>
                            <div className="Title_Txt">{pageData?.reviewed_section[3]?.title ?? ''}</div>
                            <div className="Content_Txt">{pageData?.reviewed_section[3]?.desc ?? ''}</div>
                          </div>
                      )
                    }
                  </div>
                  :
                  <div className="Et_Pb_Row2">
                    <div className="Base_Align_C">
                      <div className="Icon_ETmodules">{PREVIEWED_PAPER_DATA[0]?.icon_txt ?? ''}</div>
                      <div className="Title_Txt">{PREVIEWED_PAPER_DATA[0]?.title ?? ''}</div>
                      <div className="Content_Txt">{PREVIEWED_PAPER_DATA[0]?.desc ?? ''}</div>
                    </div>
                    <div>
                      <a href={DownloadLink} rel="noreferrer" target="_blank">
                        <div className="Btn_Read_Paper">Read Our Peer-Reviewed Paper</div>
                      </a>
                      <img src={pageData?.viewed_imgs?.length > 0 ? pageData?.viewed_imgs[0] : REVIEW_IMG1} alt="k-angled" />
                    </div>
                    <div className="Base_Align_C">
                      <div className="Icon_ETmodules">{PREVIEWED_PAPER_DATA[1]?.icon_txt ?? ''}</div>
                      <div className="Title_Txt">{PREVIEWED_PAPER_DATA[1]?.title ?? ''}</div>
                      <div className="Content_Txt">{PREVIEWED_PAPER_DATA[1]?.desc ?? ''}</div>
                    </div>

                    <div className="Base_Align_C">
                      <div className="Icon_ETmodules">{PREVIEWED_PAPER_DATA[2]?.icon_txt ?? ''}</div>
                      <div className="Title_Txt">{PREVIEWED_PAPER_DATA[2]?.title ?? ''}</div>
                      <div className="Content_Txt">{PREVIEWED_PAPER_DATA[2]?.desc ?? ''}</div>
                    </div>
                    <div>
                      <img src={pageData?.viewed_imgs?.length > 1 ? pageData?.viewed_imgs[1] : REVIEW_IMG2} alt="k-angled" />
                    </div>
                    <div className="Base_Align_C">
                      <div className="Icon_ETmodules">{PREVIEWED_PAPER_DATA[3]?.icon_txt ?? ''}</div>
                      <div className="Title_Txt">{PREVIEWED_PAPER_DATA[3]?.title ?? ''}</div>
                      <div className="Content_Txt">{PREVIEWED_PAPER_DATA[3]?.desc ?? ''}</div>
                    </div>
                  </div>
            }
          </Fade>
        </div>

        <div className="Et_Pb_Section2">
          <div className="Et_parallax_bg_wrap Peer_Section">
            <span style={{backgroundImage: 'url(' + (pageData?.middle_bg ?? Bg_Img2) + ')'}} />
          </div>

          <div className="Et_Pb_Container">
            <Fade>
              <div className="Client_Txt">Client Testimonial</div>
              {
                pageData?.testimonial_section ?
                    <div className="System_Desc_Bg">
                      <div className="Desc_Txt">
                        {pageData?.testimonial_section?.desc ?? ''}
                      </div>
                      <div className="Author_Txt">
                        {pageData?.testimonial_section?.author ?? ''}
                      </div>
                    </div>
                    :
                    <div className="System_Desc_Bg">
                      <div className="Desc_Txt">
                        “The KENZEN system has been a successful initiative for our team and how we approach heat illness risk onsite. The entire KENZEN team is resourceful and is helping us maximize management of the program. The data we’re gathering is a significant benefit for us to not only verify and confirm our current approach, but it also helps everyone understand what drives the additional mitigation measures we take in order to send everyone home safely at the end of each day.”
                      </div>
                      <div className="Author_Txt">
                        Sean Blakemore, McCarthy Construction Safety Manager, TX
                      </div>
                    </div>
              }
            </Fade>

            <div>
              <div className="Peer_Title">
                Peer-Reviewed Proof Points
              </div>

              <div className="Grid2">
                {
                  pageData?.proof_section?.length ?
                      pageData?.proof_section?.map((item, key) => {
                        if (key < 2) {
                          return (
                              <div key={key} className="Card_Content">
                                <div className="Content_Txt">{item?.content ?? ''}</div>
                                <em className="Author_Txt">{item?.author ?? ''}</em>
                              </div>
                          )
                        } else
                          return null;
                      })
                      :
                      <>
                        <div className="Card_Content">
                          <div className="Content_Txt">
                            “…The KENZEN TC algorithm is equally accurate for both biological sexes, for a range of cool to hot environmental conditions, and maintains accuracy at higher TC which predispose workers to heat injuries and illnesses”
                          </div>
                          <em className="Author_Txt">
                            (Moyen, et al.).
                          </em>
                        </div>
                        <div className="Card_Content">
                          <div className="Content_Txt">
                            “This is the first study we are aware of to show that an EKF model can be successfully implemented in a wearable device worn on the upper arm to non-invasively and accurately predict TC continuously throughout work or exercise”
                          </div>
                          <em className="Author_Txt">
                            (Moyen, et al.).
                          </em>
                        </div>
                      </>
                }
              </div>
            </div>

            <div>
              <a href={DownloadLink} rel="noreferrer" target="_blank">
                <div className="Btn_Read_Paper Peer_Btn">Read Our Peer-Reviewed Paper</div>
              </a>

              <div className="Grid2">
                {
                  pageData?.proof_section?.length > 2 ?
                      pageData?.proof_section?.map((item, key) => {
                        if (key >= 2) {
                          return (
                              <div key={key} className="Card_Content">
                                <div className="Content_Txt">{item?.content ?? ''}</div>
                                <em className="Author_Txt">{item?.author ?? ''}</em>
                              </div>
                          )
                        } else
                          return null;
                      })
                      :
                      !pageData?.proof_section ?
                          <>
                            <div className="Card_Content">
                              <div className="Content_Txt">
                                “We demonstrate that the Kenzen TC algorithm accurately predicts TC compared to ground truth measurements of TC via a gastrointestinal pill or rectal probe throughout a range of environmental conditions, exercise intensities, TC ranging from 37.0–40.2 °C, and for both biological sexes”
                              </div>
                              <em className="Author_Txt">
                                (Moyen, et al.).
                              </em>
                            </div>
                            <div className="Card_Content">
                              <div className="Content_Txt">
                                “The KENZEN device can be used to accurately monitor TC during work in cool to hot environments so that individuals can avoid heat-related injuries and illnesses via personalized alerts when an individual’s TC reaches unsafe levels”
                              </div>
                              <em className="Author_Txt">
                                (Moyen, et al.).
                              </em>
                            </div>
                          </>
                          :
                          <></>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="Et_Pb_Section3">
          <Fade>
            <div className="Et_Pb_Container">
              <div className="Et_Pb_Row3">What It Analyzes</div>

              <div className="Et_Pb_Row4">
                <div className="Grid4 Base_Fade_Animation">
                  {
                    (pageData?.analyzes_sections ?? ANALYZES_DATA)?.map((item, key) => {
                      return (
                          <div key={key}>
                            <img src={item?.image} alt="img" />
                            <div className="Et_Pb_Text_Inner">{item?.desc ?? ''}</div>
                          </div>
                      )
                    })
                  }
                </div>
              </div>

              <div className="Et_Pb_Row5">
                <div className="Et_Pb_Row3">Why It Matters</div>
              </div>
              {
                pageData?.matters_section?.length ?
                    <div className="Grid2">
                      {
                        pageData?.matters_section?.map((item, key) => {
                          return (
                              <div key={key} className="Txt_Inner">
                                <div className="Txt1">{item?.title ?? ''}</div>
                                <div className="Txt2">{item?.desc ?? ''}</div>
                                <div className="Txt3">
                                  {
                                    item?.content?.map((subItem, index) => {
                                      if (index === 1) {
                                        return (
                                            <a key={key + '_' + index} href="https://public-inspection.federalregister.gov/2021-23250.pdf" rel="noreferrer" target="_blank">
                                              {subItem ?? ''}&nbsp;
                                            </a>
                                        )
                                      } else {
                                        return (
                                            <span key={key + '_' + index}>{subItem ?? ''}&nbsp;</span>
                                        )
                                      }
                                    })
                                  }
                                </div>
                              </div>
                          )
                        })
                      }
                    </div>
                    :
                    <div className="Grid2">
                      <div className="Txt_Inner">
                        <div className="Txt1">2 Billion</div>
                        <div className="Txt2">Hours of Labor</div>
                        <div className="Txt3">
                          Climate change is expected to result in the annual loss of <a href="https://public-inspection.federalregister.gov/2021-23250.pdf" rel="noreferrer" target="_blank">2 billion labor hours</a> , translating to$160 billion in lost wages due to extreme temperatures.
                        </div>
                      </div>

                      <div className="Txt_Inner">
                        <div className="Txt1">393%</div>
                        <div className="Txt2">Workplace Hospitalizations Due To Heat Injuries</div>
                        <div className="Txt3">
                          As the planet warms up, heat injuries are also on the rise. For every 10 degree Fahrenheit increase in temperature, there is a <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5036727/" rel="noreferrer" target="_blank">393% increase in hospitalizations</a> for heat exposure.
                        </div>
                      </div>
                    </div>
              }

              <div className="Et_Pb_Row5">
                <div className="Et_Pb_Row3">News</div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="Et_Pb_Section4">
          <Fade>
            <div className="Et_Pb_Container">
              <div className="Grid2">
                <div>
                  <div className="Et_Pb_Text_Inner">CLIMATE TECH AND HEAT INJURY NEWS</div>
                  {
                    pageData?.news_section?.injury?.map((item, key) => {
                      return (
                          <div key={key} className="News_Item">
                            <div>
                              <Link to={'/news-blog/detail?id=' + item?.id} className="Title_Txt">{item?.title ?? ''}</Link>
                            </div>
                            <div>
                              <Link to={'/news-blog/detail?id=' + item?.id} className="More_Link">read more</Link>
                            </div>
                          </div>
                      )
                    })
                  }
                </div>

                <div>
                  <div className="Et_Pb_Text_Inner">WORKSITE HEAT SAFETY NEWS</div>
                  {
                    pageData?.news_section?.safety?.map((item, key) => {
                      return (
                          <div key={key} className="News_Item">
                            <div>
                              <Link to={'/news-blog/detail?id=' + item?.id} className="Title_Txt">{item?.title ?? ''}</Link>
                            </div>
                            <div>
                              <Link to={'/news-blog/detail?id=' + item?.id} className="More_Link">read more</Link>
                            </div>
                          </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
  )
};
export default SuperiorScience;
