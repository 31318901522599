// import React from "react";
// import "./index.scss";
// import {Link} from "react-router-dom";
// import {useDataContext} from "../../providers/DataProvider";

const ScheduleToday = () => {
  // const {
  //   data,
  // } = useDataContext();
  return null;
  /*return (
      <div className="ScheduleToday_Body">
        <div className="Et_Content Base_Fade_Animation">
          <div className="Record_Txt Base_Txt_Ellipse2">
            {data?.pageData?.header_section?.title ?? '2022 is on track to be the hottest year on record!'}
          </div>
          <div className="Today_Txt Base_Txt_Ellipse">
            {data?.pageData?.header_section?.desc ?? 'Schedule your demo today.'}
          </div>

          <div className="Base_Justify_Right Base_Fade_Animation">
            <Link to={data?.pageData?.header_section?.link ?? "/sales"} className="Btn_Contact_Sales">Contact Sales</Link>
          </div>
        </div>
      </div>
  )*/
};
export default ScheduleToday;
