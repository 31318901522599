import React from "react";
import "./index.scss";
import {Fade} from "../Fade";
import {useDataContext} from "../../providers/DataProvider";
import {LOCATIONS_ARR} from "../../constants";

const Locations = () => {
  const {
    data,
  } = useDataContext();

  return (
      <div className="Locations_Body">
        <Fade>
          <div className="Contents">
            <div className="Title_Txt">Locations</div>

            <div className="Grid3">
              {
                (data?.pageData?.location_section?.content ?? LOCATIONS_ARR)?.map((item, key) => {
                  return (
                      <div key={key}>
                        <div className="Sub_Title Base_Align_C">{item?.title ?? ''}</div>
                        <div className="Sub_Name_Txt Base_Align_C">{item?.name ?? ''}</div>
                      </div>
                  )
                })
              }
            </div>
          </div>
        </Fade>
      </div>
  )
};
export default Locations;