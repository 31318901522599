import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { queryGetEnumerations } from "../http";

import Loader from "../components/Loader";

const SubmitContext = React.createContext({
  isLoader: false,
  enumerations: {
    concern: [],
    health_budget: [],
    intrinsic_explosive: [],
    isFund: [],
    team_size: [],
    where_find: [],
  },
});

export const SubmitProvider = ({ children }) => {
  const location = useLocation();

  const [isLoader, setIsLoader] = useState(false);
  const [enumerations, setEnumerations] = useState([]);

  React.useEffect(() => {
    setIsLoader(true);
    queryGetEnumerations()
      .then((res) => {
        let enums = res.data?.data?.schema?.attributes ?? {};
        setEnumerations({
          concern: enums?.concern?.enum ?? [],
          health_budget: enums?.health_budget?.enum ?? [],
          intrinsic_explosive: enums?.intrinsic_explosive?.enum ?? [],
          isFund: enums?.isFund?.enum ?? [],
          industry: enums?.industry?.enum ?? [],
          team_size: enums?.team_size?.enum ?? [],
          where_find: enums?.where_find?.enum ?? [],
          why_contact: enums?.why_contact?.enum ?? [],
          purchase_timeframe: enums?.purchase_timeframe?.enum ?? [],
        });
      })
      .catch((e) => {
        console.error("getting enumerations error", e);
      })
      .finally(() => {
        setIsLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const value = {
    isLoader,
    setIsLoader,
    enumerations,
  };

  return (
    <SubmitContext.Provider value={value}>
      {isLoader && <Loader />}
      {children}
    </SubmitContext.Provider>
  );
};

export const useSubmitContext = () => {
  if (!SubmitContext)
    throw new Error("useSubmitContext should be used under SubmitProvider");
  return React.useContext(SubmitContext);
};
