import React from "react";
import "./index.scss";
import LOGO1 from "../../assets/images/commons/assp-logo.jpg";
import LOGO2 from "../../assets/images/commons/azure.jpg";
import LOGO3 from "../../assets/images/commons/Leonard_Together_RVB-300x77.jpg";
import LOGO4 from "../../assets/images/commons/NXTUS-horizontal.jpg";
import LOGO5 from "../../assets/images/commons/plug-and-play-logo-blue.451860baa333-300x57.jpg";
import {Fade} from "../Fade";
import {useDataContext} from "../../providers/DataProvider";

const LOGO_IMGS = [
  {img: LOGO1, pWidth: 100, maxWidth: 150, link: 'https://www.assp.org/', title: 'assp-logo'},
  {img: LOGO2, pWidth: 100, maxWidth: 150, link: 'https://azure.microsoft.com/en-us/', title: 'azure'},
  {img: LOGO3, pWidth: 100, maxWidth: 120, link: 'https://leonard.vinci.com/en/catalyst-the-program-designed-to-foster-and-accelerate-partnerships-between-vinci-and-startups-welcomes-11-startups-and-scale-ups/', title: 'Leonard_Together_RVB'},
  {img: LOGO4, pWidth: 100, maxWidth: 120, link: 'https://nxtus.io/our-story/', title: 'NXTUS-horizontal'},
  {img: LOGO5, pWidth: 100, maxWidth: 100, link: 'https://www.plugandplaytechcenter.com/', title: 'plug-and-play-logo-blue'},
];

const Affiliations = () => {
  const {
    data,
  } = useDataContext();

  return (
      <div className="Affiliations_Body">
        <Fade>
          <div className="Contents">
            <div className="Affiliations_Title">AFFILIATIONS</div>

            <div className="Logo_Grid5">
              {
                (data?.affiliations ?? LOGO_IMGS)?.map((item, key) => {
                  return (
                      <a key={key} href={item?.link} className="Base_Mouse_Cursor" title={item?.title} target="_blank" rel="noreferrer">
                        <img
                            src={item?.img}
                            style={{
                              margin: '0 auto',
                              width: item?.pWidth ? (item?.pWidth + '%') : '100%',
                              maxWidth: item?.maxWidth ? item?.maxWidth : '100%',
                            }}
                            alt={item?.title ?? ''}
                        />
                      </a>
                  )
                })
              }
            </div>
          </div>
        </Fade>
      </div>
  )
};
export default Affiliations;