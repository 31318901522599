import React from "react";
import "./index.scss";
import LOGO1 from "../../assets/images/commons/fastcompany-logo.png";
import LOGO2 from "../../assets/images/commons/forbes-logo.png";
import LOGO3 from "../../assets/images/commons/google-logo.png";
import LOGO4 from "../../assets/images/commons/tech-crunch-logo-1.png";
import LOGO5 from "../../assets/images/commons/wsj-logo-1.jpg";
import LOGO6 from "../../assets/images/commons/clean-energy.jpg";
import LOGO7 from "../../assets/images/commons/IT-Logo-BLACK-ok.png";
import LOGO8 from "../../assets/images/commons/compact-equipment-logo.png";
import LOGO9 from "../../assets/images/commons/CS_Logo.png";
import LOGO10 from "../../assets/images/commons/industrial-hygene-logog.png";
import LOGO11 from "../../assets/images/commons/ehsda-logo.png";
import {Fade} from "../Fade";
import {useDataContext} from "../../providers/DataProvider";

const PRESS_IMGS = [
  {img: LOGO1, maxWidth: 120, pWidth: 70, link: 'https://www.fastcompany.com/3068873/announcing-the-winners-of-the-2017-world-changing-ideas-awards', title: 'fastcompany-logo'},
  {img: LOGO2, maxWidth: 120, pWidth: 65, link: 'https://www.forbes.com/sites/jenniferhicks/2017/04/29/how-this-wearable-smart-patch-analyzes-your-sweat-to-monitor-your-body/#6a28b1c4b027', title: 'forbes-logo'},
  {img: LOGO3, maxWidth: 100, pWidth: 68, link: 'https://www.blog.google/topics/entrepreneurs/google-demo-day-shining-light-european-founders/', title: 'google-logo'},
  {img: LOGO4, maxWidth: 145, pWidth: 90, link: 'https://techcrunch.com/2016/02/06/kenzen-wins-for-best-startup-in-the-future-athlete-category-at-the-nfls-1st-and-future/', title: 'tech-crunch-logo'},
  {img: LOGO5, maxWidth: 75, pWidth: 45, link: 'https://www.wsj.com/articles/kenzen-draws-seed-funds-for-wearable-technology-1496662860', title: 'wsj-logo'},
  {img: LOGO6, maxWidth: 150, pWidth: 100, link: 'http://www.nacleanenergy.com/articles/37615/kenzen-launches-body-heat-sensor-system-for-predictive-preventative-worker-safety', title: 'clean-energy'},
  {img: LOGO7, maxWidth: 120, pWidth: 44, link: 'https://industrytoday.com/kenzen-launches-body-heat-sensor-system/', title: 'IT-Logo-BLACK-ok'},
  {img: LOGO8, maxWidth: 120, pWidth: 44, link: 'https://compactequip.com/business/kenzen-launches-body-heat-sensor-system-for-predictive-preventative-worker-safety/', title: 'compact-equipment-logo'},
  {img: LOGO9, maxWidth: 100, pWidth: 25, link: 'https://consupt.com/2020/05/kenzen-launches-smart-ppe-patch-for-worker-safety/', title: 'CS_Logo'},
  {img: LOGO10, maxWidth: 120, pWidth: 40, link: 'http://industrialhygienepub.com/new-products/kenzen-launches-body-heat-sensor-system-for-predictive-preventative-worker-safety/', title: 'industrial-hygene-logog'},
  {img: LOGO11, maxWidth: 177, pWidth: 55, link: 'https://ehsdailyadvisor.blr.com/resource/kenzen-launches-body-heat-sensor-system-for-predictive-preventive-worker-safety/', title: 'ehsda-logo'},
];

const Press = () => {
  const {
    data,
  } = useDataContext();

  return (
      <div className="Press_Body">
        <Fade>
          <div className="Contents">
            <div className="Press_Title">PRESS</div>

            <div className="Logo_Grid4">
              {
                (data?.presses ?? PRESS_IMGS)?.map((item, key) => {
                  return (
                      <a key={key} href={item?.link} className="Base_Mouse_Cursor" title={item?.title} target="_blank" rel="noreferrer" style={{width: '100%'}}>
                        <img
                            src={item?.img}
                            style={{
                              width: item?.pWidth ? (item?.pWidth + '%') : '100%',
                              maxWidth: item?.maxWidth ? item?.maxWidth : '100%',
                            }}
                            alt={item?.title ?? ''}
                        />
                      </a>
                  )
                })
              }
            </div>
          </div>
        </Fade>
      </div>
  )
};
export default Press;