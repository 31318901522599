import React, {useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useWindowScroll, useWindowSize,} from "../../utils/hooks/index";
import "./index.scss";
import {HEADER_MENUS} from "../../constants";
import LogoImg from "../../assets/images/header/kenzen-logo-ko.png";

const Header = () => {
  const location = useLocation();
  const getSize = useWindowSize();
  const getScroll = useWindowScroll();
  const [isShowDropDown, setIsShowDropDown] = useState(false);

  const currentRouter = useMemo(() => {
    if (!location?.pathname) return null;

    setIsShowDropDown(false);

    return HEADER_MENUS.findIndex(it => {
      let subMenus = (it?.subMenus || []).map(_it => (_it?.link ?? ''));
      subMenus.push(it?.link ?? '');
      return subMenus?.includes(location?.pathname)
    });
  }, [location?.pathname]);

  return (
      <div
          className="Header_Body"
          style={{
            height: getScroll?.scrollY > 50 ? (getSize?.width > 992 ? 54 : 76) : (getSize?.width > 992 ? 89 : 80)
          }}
      >
        <div className="Container Base_Flex_Space">
          <Link to='/' className="Base_Justify_Left">
            <img src={LogoImg} className="Logo_Img" alt="" />
          </Link>

          <div className="Base_Justify_Right Mobile_Hidden" style={{height: '100%'}}>
            {
              HEADER_MENUS?.map((item, key) => {
                return (
                    <div
                        key={key}
                        className={`Menu_Item ${currentRouter === key ? 'Active' : ''} ${item?.subMenus?.length > 0 ? 'Down_Icon' : ''}`}
                        style={{height: '100%'}}
                    >
                      <Link to={item?.link ?? ''} className="">{item?.name ?? ''}</Link>

                      {
                        item?.subMenus?.length > 0 && (
                            <div
                                className="Sub_Menu"
                                style={{
                                  top: getScroll?.scrollY > 50 ? (getSize?.width > 992 ? 54 : 76) : (getSize?.width > 992 ? 89 : 80)
                                }}
                            >
                              {
                                item?.subMenus?.map((subItem, subKey) => {
                                  return (
                                      <Link to={subItem?.link ?? ''} key={subKey} className="Menu_Item">
                                        {subItem?.name ?? ''}
                                      </Link>
                                  )
                                })
                              }
                            </div>
                        )
                      }
                    </div>
                )
              })
            }
          </div>

          <div className="Mobile_Shown" onClick={() => setIsShowDropDown(!isShowDropDown)} />

          {
            (isShowDropDown && getSize?.width < 1200) && (
                <div
                    className="Mobile_Sub_Menu"
                    style={{
                      display: isShowDropDown ? 'block' : 'none',
                      top: getScroll?.scrollY > 50 ? (getSize?.width > 992 ? 54 : 76) : (getSize?.width > 992 ? 89 : 80)
                    }}
                >
                  {
                    HEADER_MENUS?.map((item, key) => {
                      return (
                          <div key={key} className="Mobile_Menu_Item">
                            <div className="Base_Txt_Ellipse"><Link to={item?.link}>{item?.name ?? ''}</Link></div>

                            <div>
                              {
                                item?.subMenus?.map((subItem, subKey) => {
                                  return (
                                      <div key={subKey} className="Mobile_Sub_Menu_Item Base_Txt_Ellipse">
                                        <Link to={subItem?.link ?? ''}>
                                          {subItem?.name ?? ''}
                                        </Link>
                                      </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                      )
                    })
                  }
                </div>
            )
          }
        </div>
      </div>
  );
};
export default Header;