import React from "react";
import "./index.scss";

import {Fade} from "../../components/Fade";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import Locations from "../../components/Locations";
import Press from "../../components/Press";
import {
  LEADERSHIP_IMGS,
} from "../../constants";
import {
  LeadershipSlider
} from "../../components/CustomSlider";
import {useDataContext} from "../../providers/DataProvider";
import HeaderBgImg from "../../assets/images/contactUs/hands-short.jpg";

const LeadershipTeamBoard = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
    <div className="LeadershipTeamBoard_Body">
      <div className="Et_parallax_bg_wrap">
        <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}}/>
        <div className="Et_Pb_Slide_Overlay_Container"/>
      </div>
      <Fade>
        <div className="Get_Started_Content">
          <div className="Today_Txt Base_Justify_Left">
            {pageData?.hero_section?.title ?? 'LEADERSHIP, TEAM, & BOARD'}
          </div>
        </div>
      </Fade>

      <div className="LeadershipSection">
        <div className="Header">
          <p>KENZEN Leadership</p>
        </div>

        <div className="Body">
          <div className="Row">
            {
              data?.members?.map((member, index) => (
                <div className="Item" key={`member-${index}`}>
                  <div className="TeamMemberDescription">
                    <img
                      decoding="async"
                      width="600"
                      height="600"
                      src={member.photo?.data?.attributes?.url}
                      alt={member.name}
                      className="ProfileImg"
                    />

                    <p className="Name">{member.name}</p>
                    <p className="Position">{member.position}</p>
                    <div className="Experience">
                      {
                        member.experience && (
                          <ul>
                            {
                              member.experience?.map((exp, i) => (
                                <li key={`member-${index}-experience-${i}`}>
                                  {
                                    exp.description
                                  }
                                </li>
                              ))
                            }
                          </ul>
                          )
                      }
                    </div>
                  </div>

                  <div className="SocialContact">
                    {
                      member.social_contacts?.linkedin &&
                      <div className="SocialContactItem">
                        <a href={member.social_contacts?.linkedin} target="_blank" rel="noreferrer">
                      <span>
                        {/*fixme remove hardcode*/}
                      <img decoding="async" width="25" height="25"
                           src="https://kenzendevfoo.blob.core.windows.net/strapi-uploads/assets/linkedin_kenzen_icon_1adba89116" alt="Linkedin"
                           title="linkedin-kenzen-icon" />
                      </span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className="DirectorsSection">
        <div className="Container">
          <div className="Header">
            <p>KENZEN BOARD OF DIRECTORS</p>
          </div>

          <div className="Body">
            <div className="Row">
              {
                data?.directors?.map((director, index) => (
                  <div className="Item" key={`director-${index}`}>
                    <img
                      src={director.photo?.data?.attributes?.url}
                      alt={director.name}
                      className="Avatar"
                    />
                    <div>
                      <p className="Name">{director.name}</p>
                      <p className="Position">{director.position}</p>
                      <p className="Additional">{director.additional}</p>
                      {
                        director.social_contacts?.linkedin &&
                        <a href={director.social_contacts?.linkedin} className="Linkedin"></a>
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>

      <div className="Et_Pb_Section_3">
        <Fade>
          <div className="Et_Pb_Container">
            <div className="Title_Txt">Kenzen Investors</div>
            <div className="Grid5">
              {
                (data?.investors ?? LEADERSHIP_IMGS)?.map((item, key) => {
                  return (
                    <div key={key} style={{maxWidth: item?.maxWidth ?? '100%'}}>
                      <a href={item?.link ?? ''} target="_blank" rel="noreferrer">
                        <img src={item?.img ?? ''} alt={item?.title ?? ''}/>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Fade>
      </div>

      <LeadershipSlider
        container={pageData?.slider_section}
      />

      {
        pageData?.help_section ?
          <FindOut
            title={<span style={{fontSize: 30}}>{pageData?.help_section?.title ?? ''}</span>}
            desc={pageData?.help_section?.desc ?? ''}
            btnName={pageData?.help_section?.btn_name ?? ''}
            link={pageData?.help_section?.link ?? ''}
            cssCls='Get_Alerts_Body'
          />
          :
          <FindOut
            title={<span style={{fontSize: 30}}>GET ALERTS</span>}
            desc='1'
            btnName='JOIN OUR LIST'
            link='/sales'
            cssCls='Get_Alerts_Body'
          />
      }

      <Locations/>

      {
        pageData?.is_show_press_section && (
          <Press/>
        )
      }

      {
        pageData?.is_show_affiliations_section && (
          <Affiliations/>
        )
      }
    </div>
  )
};
export default LeadershipTeamBoard;
