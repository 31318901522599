import React from "react";
import "./index.scss";
import ScheduleToday from "../../components/ScheduleToday";
import Press from "../../components/Press";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import {WorkerSlider} from "../../components/CustomSlider";
import {
  TECH_DEVELOPMENT_DATA,
} from "../../constants";
import GuardingMonitoring from "../../components/GuardingMonitoring";
import {Fade} from "../../components/Fade";
import {useDataContext} from "../../providers/DataProvider";
import HeaderBgImg from "../../assets/images/soultion/GettyImages-102285500-renewable-1920.jpg";

const TechDevelopment = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="TechDevelopment_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>

        <ScheduleToday/>

        {
          pageData?.hero_section ?
              <GuardingMonitoring
                  title={pageData?.hero_section?.title ?? ""}
                  subTitle={pageData?.hero_section?.sub_title ?? ""}
                  desc={pageData?.hero_section?.desc ?? []}
              />
              :
              <GuardingMonitoring
                  title="CRAFTING THE FUTURE OF SAFETY"
                  subTitle="SAFETY TECH IN DEVELOPMENT"
                  desc={[
                    "The power of predictive analytics and prevention is changing the future of safety tech, giving managers the muscle to predict and prevent unsafe conditions before they occur."
                  ]}
              />
        }

        <div className="Et_Pb_Section">
          <Fade>
            <div className="Et_Pb_Row2 Base_Fade_Animation">
            <span className="Txt1">
              {pageData?.metric_section?.solution ?? 'KENZEN solutions integrate machine learning, giving managers the power to predict and prevent heat-related injury and illness by:'}
            </span>

              {
                pageData?.metric_section?.content?.length ?
                    <ul>
                      {
                        pageData?.metric_section?.content?.map((item, key) => {
                          return (
                              <li key={key}>{item ?? ''}</li>
                          )
                        })
                      }
                    </ul>
                    :
                    <ul>
                      <li>Analyzing data to create ideal work-rest calculations</li>
                      <li>Adjusting shift times to accommodate lower temperatures</li>
                      <li>Allowing higher-risk workers to work at lower-risk times</li>
                      <li>Giving workers the ability to understand and improve their own health indicators</li>
                      <li>Training managers and workers to look forward and prevent heat-related injuries before they happen, rather than only looking backward at past incidents</li>
                    </ul>
              }

              <div className="Card_Temp">
                {
                  pageData?.metric_section?.detail ?
                      <>
                        <div className="Percentage_P">{pageData?.metric_section?.detail?.percentage}%</div>
                        <p>
                          {
                            pageData?.metric_section?.detail?.list?.map((item, key) => {
                              if (key !== 1) {
                                return (
                                    <em key={key}>{item ?? ''} &nbsp;</em>
                                )
                              } else {
                                return (
                                    <a key={key} href={pageData?.metric_section?.detail?.link ?? 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5036727'}>
                                      <em>{item ?? ''} &nbsp;</em>
                                    </a>
                                )
                              }
                            })
                          }
                        </p>
                      </>
                      :
                      <>
                        <div className="Percentage_P">393%</div>
                        <p>
                          <em>For every 10 degree Fahrenheit increase in temperature, there is a </em>
                          <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5036727">
                            <em>393% increase in hospitalizations</em>
                          </a>
                          <em> for heat exposure.</em>
                        </p>
                      </>
                }
              </div>
            </div>

            <div className="Et_Pb_Row3">
              Kenzen Metrics
            </div>

            <div className="Et_Pb_Row4">
              <div className="Grid4 Base_Fade_Animation">
                {
                  (pageData?.metrics_image_texts ?? TECH_DEVELOPMENT_DATA)?.map((item, key) => {
                    return (
                        <div key={key}>
                          <img src={item?.image} alt="img" />
                          <div className="Et_Pb_Text_Inner">{item?.desc ?? ''}</div>
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </Fade>
        </div>

        {
          pageData?.help_section ?
              <FindOut
                  title={pageData?.help_section?.title ?? ''}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
              />
              :
              <FindOut
                  title='HOW WE ARE ADDRESSING COVID-19'
                  desc='The KENZEN solution helps detect invisible threats, such as COVID-19 and other infectious diseases.'
                  btnName='Find Out More'
                  link='/sales'
              />
        }

        <WorkerSlider
            container={pageData?.slider_section ?? ''}
        />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default TechDevelopment;
