import React from "react";
import "./index.scss";
import ScheduleToday from "../../components/ScheduleToday";
import Press from "../../components/Press";
import Affiliations from "../../components/Affiliations";
import FindOut from "../../components/FindOut";
import {WorkerSlider} from "../../components/CustomSlider";
import GuardingMonitoring from "../../components/GuardingMonitoring";
import Industries from "../../components/Industries";
import Img_Map1 from "../../assets/images/soultion/KENZEN-map.jpg";
import HeaderBgImg from "../../assets/images/soultion/GettyImages-661410282-welder-woman-1920.jpg";
import {Fade} from "../../components/Fade";
import {useDataContext} from "../../providers/DataProvider";

const BenefittingIndustries = () => {
  const {
    data,
  } = useDataContext();

  const pageData = React.useMemo(() => {
    return data?.pageData;
  }, [data]);

  return (
      <div className="BenefittingIndustries_Body">
        <div className="Et_parallax_bg_wrap">
          <span style={{backgroundImage: 'url(' + (pageData?.header_bg ?? HeaderBgImg) + ')'}} />
          <div className="Et_Pb_Slide_Overlay_Container" />
        </div>

        <ScheduleToday/>

        {
          pageData?.hero_section ?
              <GuardingMonitoring
                  title={pageData?.hero_section?.title ?? ""}
                  subTitle={pageData?.hero_section?.sub_title ?? ""}
                  desc={pageData?.hero_section?.desc ?? []}
              />
              :
              <GuardingMonitoring
                  title="BENEFITTING ALL BOOTS ON THE GROUND"
                  subTitle="BENEFITTING INDUSTRIES"
                  desc={[
                    "KENZEN’s solutions are geared toward industries that keep the economic engine running. Our safety monitoring devices support people who work for a living – the frontline workers, the blasters, the wind techs, and the ironworkers. "
                  ]}
              />
        }

        <div className="Et_Pb_Section5">
          <Fade>
            <div className="Section5_Container">
              <div className="Section_Item1">
                {pageData?.keep_section?.title ?? 'KENZEN keeps workers safe in a wide range of industrial and health care sectors, from mining and clean energy to nursing and oil & gas.'}
              </div>

              {
                pageData?.keep_section?.content?.length ?
                    pageData?.keep_section?.content?.map((item, key) => {
                      return (
                          <div key={key} className={(key !== 0 && key !== pageData?.keep_section?.content?.length - 1) ? "Section_Item3" : "Section_Item2"}>
                            {
                              item?.map((subItem, index) => {
                                return (
                                    <div key={key + '_' + index}>{subItem ?? ''}</div>
                                )
                              })
                            }
                          </div>
                      )
                    })
                    :
                    <>
                      <div className="Section_Item2">
                        <div>OIL & GAS</div>
                        <div>RENEWABLE ENERGY</div>
                        <div>MINING</div>
                      </div>

                      <div className="Section_Item3">
                        <div>OIL & GAS</div>
                        <div>POWER GENERATION</div>
                        <div>MANUFACTURING</div>
                        <div>TRANSPORTATION</div>
                      </div>

                      <div className="Section_Item2">
                        <div>EMERGENCY SERVICES</div>
                        <div>FRONT LINE WORKERS</div>
                        <div>MILITARY</div>
                      </div>
                    </>
              }
            </div>
          </Fade>
        </div>

        {
          pageData?.help_section ?
              <FindOut
                  title={pageData?.help_section?.title ?? ''}
                  desc={pageData?.help_section?.desc ?? ''}
                  btnName={pageData?.help_section?.btn_name ?? ''}
                  link={pageData?.help_section?.link ?? ''}
              />
              :
              <FindOut
                  title='HOW WE ARE ADDRESSING COVID-19'
                  desc='The KENZEN solution helps detect invisible threats, such as COVID-19 and other infectious diseases.'
                  btnName='Find Out More'
                  link='/sales'
              />
        }

        <WorkerSlider
            container={pageData?.slider_section ?? ''}
        />

        <div className="Et_Pb_Section6">
          <Fade>
            <div className="Section6_Container">
              <div>
                <div className="Title_Txt">{pageData?.map_section?.title ?? 'KENZEN IN THE FIELD'}</div>
                <div className="Desc_Txt">
                  {pageData?.map_section?.desc ?? 'Kenzen has deployed to workforces across the globe in fields such as renewable energy, construction, energy, manufacturing, and mining.'}
                </div>
              </div>
              <div>
                <img src={pageData?.map_section_img ?? Img_Map1} className="Img_W100" alt="img map" />
              </div>
            </div>
          </Fade>
        </div>

        <Industries
            bgImage={pageData?.author_section_bg}
            title={pageData?.author_section?.title ?? 'BENEFITTING INDUSTRIES'}
            authorDate={pageData?.author_section?.desc ?? 'by Christa Bianchi | Jun 2, 2020 |'}
        />

        {
          pageData?.is_show_press_section && (
              <Press/>
          )
        }

        {
          pageData?.is_show_affiliations_section && (
              <Affiliations/>
          )
        }
      </div>
  )
};
export default BenefittingIndustries;
