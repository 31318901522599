import * as React from 'react';
import {useState} from "react";
import {useLocation} from "react-router-dom";

import {
  queryAffiliation,
  queryCategories,
  queryInvestor,
  queryLocation,
  queryPress,
  queryPageData,
  queryNewsBlog,
  queryNewsBlogDetail,
  queryNewsBlogPerAuthor, queryMember, queryDirector,
} from "../http";
import {
  GetCurrentParam,
  JoinStringFromArr,
  resetJsonFormat,
  PAGINATION,
  RECENT_PAGINATION,
  CONTACT_US_PAGINATION,
  SUBMIT_FORM_ORDER,
} from "../constants";
import Loader from "../components/Loader";

const MyContext = React.createContext({
  isLoader: false,
  categoryId: '',
  keyword: '',
  newsDetail: {},
  data: {
    posts: [],
    categories: [],
    pagination: {},
    affiliations: [],
    presses: [],
    locations: [],
    investors: [],
    pageData: {},
  },
  authorNews: {
    posts: [],
    pagination: {},
  }
});

export const DataProvider = ({
  children,
  pageNo,
}) => {

  const location = useLocation();

  const [isLoader, setIsLoader] = useState(false);
  const [newsDetail, setNewsDetail] = useState('');
  const [authorNews, setAuthorNews] = useState(null);
  const [data, _setData] = useState(null);
  const dataRef = React.useRef(data);
  const setData = v => {
    _setData(v);
    dataRef.current = v;
  };

  const [keyword, setKeyword] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const page = Math.max(GetCurrentParam('page'), 1);
  const id = GetCurrentParam('id');

  React.useEffect(() => {
    setKeyword('');
    setCategoryId('');
  }, [location?.pathname]);

  React.useEffect(() => {
    if (!pageNo) return null;

    let apiQuery = '';
    switch (pageNo) {
      case 1:
        apiQuery = '/landing-page';
        break;
      case 2:
        apiQuery = '/health-morning-page';
        break;
      case 3:
        apiQuery = '/benefitting-industries-page';
        break;
      case 4:
        apiQuery = '/safety-tech-page';
        break;
      case 5:
        apiQuery = '/safety-working-page';
        break;
      case 6:
        apiQuery = '/science-page';
        break;
      case 7:
        apiQuery = '/about-us-leadership-page';
        queryMember()
          .then((res) => {
            const members = res.data?.data?.map((it) => it.attributes);
            setData({
              ...dataRef.current,
              members,
            });
          });
        queryDirector()
          .then((res) => {
            const directors = res.data?.data?.map((it) => it.attributes);
            setData({
              ...dataRef.current,
              directors,
            });
          });
        break;
      case 8:
        apiQuery = '/about-us-addressing-page';
        break;
      case 9:
        apiQuery = '/about-us-tech-smart-page';
        break;
      case 10:
        apiQuery = '/website-privacy-policy-page';
        break;
      case 11:
        apiQuery = '/website-privacy-policy-page';
        break;
      case 12:
        apiQuery = '/contact-us-sale-page';
        break;
      case 13:
        apiQuery = '/contact-us-press-inquiry-page';
        break;
      case 14:
        apiQuery = '/about-us-rd-page';
        break;
      case 15:
        apiQuery = '/contact-us-jobs-page';
        break;
      case 16:
        apiQuery = '/contact-us-investor-inquiry-page';
        break;
      case 17:
        apiQuery = '/contact-us-support-page';
        break;
      case 18:
        apiQuery = '/news-blog-page';
        break;
      case 21:
        apiQuery = '/terms-of-use';
        break;

      default:
        break;
    }

    if (apiQuery) {
        let promises = [
        queryAffiliation(),
        queryPress(),
        queryInvestor(),
        queryLocation(),
        queryPageData(apiQuery),
      ];
      if (pageNo === 6) {
        // fixme remove hardcode
        promises.push(queryNewsBlog('', 1, 3, 6));
        promises.push(queryNewsBlog('', 1, 3, 13));
      }

      setIsLoader(true);

      const a = () => new Promise(resolve => {
        Promise.allSettled(promises)
            .then(results => {

              let initJson = {};
              results?.forEach((result, index) => {
                if (result.status === `fulfilled`) {
                  if (index === 0) {
                    initJson = Object.assign({}, initJson, { affiliations: resetJsonFormat(result.value?.data?.data) });
                  } else if (index === 1) {
                    initJson = Object.assign({}, initJson, { presses: resetJsonFormat(result.value?.data?.data) });
                  } else if (index === 2) {
                    initJson = Object.assign({}, initJson, { investors: resetJsonFormat(result.value?.data?.data) });
                  } else if (index === 3) {
                    initJson = Object.assign({}, initJson, {locations: result.value?.data?.data?.length > 0 ? (result.value?.data?.data[0]?.attributes?.content ?? []) : []});
                  } else if (index === 4) {
                    let attributeData = result.value?.data?.data?.attributes;
                    let keys = Object.keys(attributeData);
                    let pageData = attributeData;

                    keys.forEach(it => {
                      switch (it) {
                        case "header_bg":
                        case "protection_img":
                        case "author_section_bg":
                        case "monitor_bg":
                        case "chart_bg":
                        case "map_section_img":
                        case "middle_bg":
                          pageData = {
                            ...pageData,
                            [[it]]: attributeData[[it]]?.data?.attributes?.url ?? ''
                          };
                          break;

                        case "help_section":
                        case "slider_section":
                        case "submit_section":
                        case "video_section":
                        case "location_section":
                          if (Object.keys(attributeData[[it]]?.data?.attributes)?.includes('image')) {
                            attributeData[[it]].data.attributes = {
                              ...attributeData[[it]]?.data?.attributes,
                              image: attributeData[[it]]?.data?.attributes?.image?.data?.attributes?.url ?? ''
                            };
                          }

                          if (it === 'submit_section') {
                            let submitForm = {};
                            SUBMIT_FORM_ORDER?.forEach(key => {
                              if (Object.keys(attributeData[[it]]?.data?.attributes?.init_forms)?.includes(key)) {
                                submitForm = Object.assign({}, submitForm, {[[key]]: attributeData[[it]]?.data?.attributes?.init_forms[[key]]})
                              }
                            });

                            attributeData[[it]].data.attributes = Object.assign({}, attributeData[[it]]?.data?.attributes, {init_forms: submitForm})
                          }

                          pageData = {
                            ...pageData,
                            [[it]]: attributeData[[it]]?.data?.attributes
                          };
                          break;

                        case "icon_text":
                        case "metrics_image_texts":
                        case "end_game_image_text":
                        case "analyzes_sections":
                        case "phase_section_imgs":
                        case "platform_image_text":
                          pageData = Object.assign({}, pageData, {[[it]]: attributeData[[it]]?.data?.map(it => (
                              Object.keys(it?.attributes)?.includes('image') ?
                                  { ...it?.attributes, image: it?.attributes?.image?.data?.attributes?.url ?? ''}
                                  :
                                  { ...it?.attributes, }
                            ))});
                          break;

                        case "viewed_imgs":
                          pageData = {
                            ...pageData,
                            [[it]]: attributeData[[it]]?.data?.map(it => (it?.attributes?.url))
                          };
                          break;

                        default:
                          break;
                      }
                    });

                    initJson = Object.assign({}, initJson, {pageData: pageData});
                  } else if (index === 5) {
                    initJson = Object.assign({}, initJson, {
                      pageData: {
                        ...initJson?.pageData,
                        news_section: {
                          ...initJson?.pageData?.news_section,
                          injury: result.value?.data?.data?.map(it => ({id: it?.id, title: it?.attributes?.title ?? ''})),
                        }
                      }});
                  } else if (index === 6) {
                    initJson = Object.assign({}, initJson, {
                      pageData: {
                        ...initJson?.pageData,
                        news_section: {
                          ...initJson?.pageData?.news_section,
                          safety: result.value?.data?.data?.map(it => ({id: it?.id, title: it?.attributes?.title ?? ''})),
                        }
                      }});
                  }
                }
              });

              setData({
                ...dataRef.current,
                ...initJson,
              })
            })
            .catch(e => console.log(e.toString()))
            .finally(() => {
              resolve();
              setIsLoader(false);
            });
      });
      Promise.allSettled([a()]).then();
    }
  }, [pageNo]);

  React.useEffect(() => {
    if (!pageNo) return;
    if (![12, 13, 14, 15, 16, 17, 18, 19, 20].includes(pageNo)) return;

    const promises = [
      queryCategories(),
      queryNewsBlog(keyword, location?.pathname?.includes('/news-blog') ? page : 1, categoryId ? RECENT_PAGINATION : (location?.pathname?.includes('news-blog') ? PAGINATION : CONTACT_US_PAGINATION), categoryId)
    ];

    setIsLoader(true);
    const a = () => new Promise(resolve => {
      Promise.allSettled(promises)
          .then(results => {
            results?.forEach((result, index) => {
              if (result.status === `fulfilled`) {
                if (index === 0) {
                  setData({
                    ...dataRef.current,
                    categories: result.value?.data?.data?.map(it => ({
                      id: it?.id ?? '',
                      name: it?.attributes?.name ?? '',
                    })) ?? []
                  })
                } else if (index === 1) {
                  setData({
                    ...dataRef.current,
                    posts: result.value?.data?.data?.map(it => ({
                      id: it?.id ?? '',
                      authorId: `${it?.attributes?.admin_user?.data?.id ?? ''}`,
                      categoryNames: JoinStringFromArr(it?.attributes?.categories?.data ?? []),
                      title: it?.attributes?.title ?? '',
                      date: it?.attributes?.date ?? '',
                      updatedAt: it?.attributes?.updatedAt ?? '',
                      content: it?.attributes?.content ?? '',
                      thumbnail: it?.attributes?.thumbnail?.data?.attributes?.url ?? '',
                      authorName: `${it?.attributes?.admin_user?.data?.attributes?.firstname ?? ''}`,
                    })) ?? [],
                    pagination: result.value?.data?.meta?.pagination ?? {},
                  })
                }
              }
            })
          })
          .catch(e => console.log(e.toString()))
          .finally(() => {
            resolve();
            setIsLoader(false);
          });
    });
    Promise.allSettled([a()]).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, categoryId, keyword, pageNo]);

  React.useEffect(() => {
    if (id) {
      setIsLoader(true);
      queryNewsBlogDetail(id)
          .then(res => {
            let detail = {};
            if (res.data?.data?.length > 0) {
              detail = res.data?.data[0];
            }
            setNewsDetail({
              id: detail?.id ?? '',
              authorId: `${detail?.attributes?.admin_user?.data?.id ?? ''}`,
              categoryNames: JoinStringFromArr(detail?.attributes?.categories?.data ?? []),
              title: detail?.attributes?.title ?? '',
              date: detail?.attributes?.date ?? '',
              updatedAt: detail?.attributes?.updatedAt ?? '',
              content: detail?.attributes?.content ?? '',
              thumbnail: detail?.attributes?.thumbnail?.data?.attributes?.url ?? '',
              authorName: `${detail?.attributes?.admin_user?.data?.attributes?.firstname ?? ''}`,
            })
          })
          .catch(e => {
            console.error("getting news Detail error", e);
          })
          .finally(() => {
            setIsLoader(false);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /**
   * News List per author
   */
  React.useEffect(() => {
    if (location?.pathname === '/news-blog/author') {
      if (!setAuthorNews) setAuthorNews({});
      else {
        setIsLoader(true);
        queryNewsBlogPerAuthor(page, RECENT_PAGINATION, id)
            .then(res => {
              setAuthorNews({
                posts: res.data?.data?.map(it => ({
                  id: it?.id ?? '',
                  authorId: `${it?.attributes?.admin_user?.data?.id ?? ''}`,
                  categoryNames: JoinStringFromArr(it?.attributes?.categories?.data ?? []),
                  title: it?.attributes?.title ?? '',
                  date: it?.attributes?.date ?? '',
                  updatedAt: it?.attributes?.updatedAt ?? '',
                  content: it?.attributes?.content ?? '',
                  thumbnail: it?.attributes?.thumbnail?.data?.attributes?.url ?? '',
                  authorName: `${it?.attributes?.admin_user?.data?.attributes?.firstname ?? ''}`,
                })) ?? [],
                pagination: res.data?.meta?.pagination ?? {},
              })
            })
            .catch(e => {
              console.error("getting news Detail error", e);
            })
            .finally(() => {
              setIsLoader(false);
            });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, location?.pathname, id]);

  const value = {
    isLoader,
    page,
    newsDetail,
    authorNews,
    data,
    keyword,
    setKeyword,
    categoryId,
    setCategoryId,
  };

  return (
      <MyContext.Provider
          value={value}
      >
        {isLoader && (
            <Loader/>
        )}
        {children}
      </MyContext.Provider>
  )
};

export const useDataContext = () => {
  if (!MyContext)
    throw new Error('useDataContext should be used under DataProvider');
  return React.useContext(MyContext);
};
