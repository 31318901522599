import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./index.scss";
import {Fade} from "../Fade";
import {
  LEADERSHIP_SLIDER_TEXT,
  SLIDER_TEXT,
} from "../../constants";

import Slider3Img from "../../assets/images/home/iStock-616901104-pipes-darker-1920x1080-1.jpg";
import Slider4Img from "../../assets/images/about/sonia-steve-hero02.jpg";

const setting1 = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  centerPadding: 30,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: true,
};

const setting2 = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  centerPadding: 30,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: true,
};

export const WorkerSlider = (container) => {
  return (
      <div
          className="Slider_Body"
          style={{
            backgroundImage: 'url(' + (container?.image || Slider3Img) + ')'
          }}
      >
        <Fade>
          <div className="Slide_Description">
            <Slider {...setting1}>
              {
                (container?.content ?? SLIDER_TEXT)?.map((item, key) => {
                  return (
                      <div key={`worker-slider-${key}`}>
                        <div className="Slide_Title">
                          {item?.title ?? ''}
                        </div>
                        <div className="Slide_Sub_Title">{item?.sub_title ?? ''}</div>
                        {
                          item?.desc?.map((subItem, index) => {
                            return (
                                <div key={`worker-slider-${key}-item-${index}`} className="Slide_Content">{subItem ?? ''}</div>
                            )
                          })
                        }
                      </div>
                  )
                })
              }
            </Slider>
          </div>
        </Fade>
      </div>
  )
};

export const LeadershipSlider = (container) => {
  return (
      <div
          className="Slider_Body"
          style={{
            backgroundImage: 'url(' + (container?.image || Slider4Img) + ')'
          }}
      >
        <Fade>
          <div className="Slide_Description">
            <Slider {...setting2}>
              {
                (container?.content ?? LEADERSHIP_SLIDER_TEXT)?.map((item, key) => {
                  return (
                      <div key={`leadership-slider-${key}`}>
                        <div className="Slide_Title">
                          {item?.title ?? ''}
                        </div>
                        <div className="Slide_Content" key={`leadership-slider-${key}-description`}>{item?.desc ?? ''}</div>
                        {/*<div className="Leadership_Content" key={`leadership-slider-${key}-description`}>{item?.desc ?? ''}</div>*/}
                      </div>
                  )
                })
              }
            </Slider>
          </div>
        </Fade>
      </div>
  )
};

const SliderComponent = {
  WorkerSlider,
  LeadershipSlider,
};

export default SliderComponent;
